import { copyToClipboardWeb, setPageData, selectAll } from "../Libs";
import { videoTuto, tEditor, dV_, hToD } from "./SourceCodeContent";



function SourceCodePartial(props) {
    const xz = props.name;
    if (xz === "5") {
        return <></>;
    }

    let defaultContent = "", htmlCode = "", cssCode = "", jsCode = "", info = "";
    // let data_ = null;

    function BlaBla(d) {
        htmlCode = d.htmlCode;
        cssCode = d.cssCode;
        jsCode = d.jsCode;
        info = d.info;
        defaultContent = htmlCode;
    };

    //3= video player, 4 = hex-to-decimal, 6 = te, 7 = data-visualization
    if (xz === '3') {
        // data_ = videoTuto;
        BlaBla(videoTuto);
        setPageData(
            'Custom Video Player Exactly Like YouTube Video Player',
            "How to create video player using html css and javascript?",
            "create custom video player exactly like youtube video player with pure html css and javascript",
            "html, css, javascript, js, build custom video player, build custom video player with javascript, build custom video player using html css and javascript"
        );
    }

    if (xz === '4') {
        // data_ = hToD;
        BlaBla(hToD);
        setPageData(
            'Convert Hex to Decimal using JavaScript',
            "How to convert Hex to Decimal using javascript?",
            "Convert hex to decimal with javascript and html page",
            "html, css, javascript, js, hex to decimal, decimal to hex, hex convertor, decimal convertor, convert hext to decimal using javascript, convert decimal to hex using javascript"
        );
    }

    if (xz === '6') {
        // data_ = tEditor;
        BlaBla(tEditor);
        setPageData(
            'Online Text / Code Editor JavaScript',
            "How to create online text or code editor using html css and javascript?",
            "Here I teach you how to create a text or code editor with pure html css and javascript",
            "html, css, javascript, js, build online text editor, build online text editor with javascript, build code editor using html css and javascript, create text editor, build code editor, create your own notepad"
        );
    }

    if (xz === '7') {
        // data_ = dV_;
        BlaBla(dV_);
        setPageData(
            'Data Visualization Part 1',
            "How to create your own data visualization library using html css and javascript?",
            "create your own data visualization library exactly like youtube your own data visualization library with pure html css and javascript",
            "html, css, javascript, js, build your own data visualization library, build data visualization library with javascript, build data visualization tools using html css and javascript"
        );
    }

    // if (data_)
    // {
    //     htmlCode = data_.htmlCode;
    //     cssCode = data_.cssCode;
    //     jsCode = data_.jsCode;
    //     info = data_.info;
    //     defaultContent = htmlCode;
    // }



    const injectContent = (e) => {
        const t = e.target;
        const rawCodeHolder = document.querySelector(".raw-code");

        const currents_ = document.querySelector(".c");
        currents_.classList.remove("c");

        t.classList.add("c");

        switch (t.textContent) {
            case "HTML":
                rawCodeHolder.value = htmlCode.trim();
                break;
            case "CSS":
                rawCodeHolder.value = cssCode;
                break;
            case "JS":
                rawCodeHolder.value = jsCode;
                break;
            default:
                break;
        }
    };

    return (

        <section className="sc">
            <h3>Useful information</h3>
            <p dangerouslySetInnerHTML={{ __html: info }}></p>
            <p>If the "Copy" button does not work, then click on "Select All" button and use the shortcut keys <span className="code-1">[CTRL + C]</span> on your keyboard to copy the code.
                This way you can paste the code into your Code Editor. </p>
            <header className="sc-nav">
                <div className="html c" onClick={injectContent}>HTML</div>
                <div className="css" onClick={injectContent}>CSS</div>
                <div className="js" onClick={injectContent}>JS</div>
                <div className=" copy-raw-code"
                    onClick={(e) => { copyToClipboardWeb(document.querySelector('.raw-code').value); }}>Copy</div>
                <div onClick={() => { selectAll(document.querySelector('.raw-code')); }} className="select-all">Select All</div>
            </header>
            <div className="sc-wrapper">
                <textarea name="html" id="html-view" className="raw-code" readOnly value={defaultContent}></textarea>
            </div>
        </section>
    );
}

export default SourceCodePartial;