import TopNav from "./TopNav";
import Footer from "./Footer";
function Contact() {
    document.title = "Contact";

    return (
        <>
            {/* <!-- nav bar --> */}
            <TopNav cls="Contact_page" />

            {/* <header className="hidden feature-hdr">
                <div>Aqyanoos.com creates useful applications for everyone.</div>
            </header> */}

            {/* <!-- main part of the web page --> */}
            <div id="contact">

                <header>
                    <h1 className="title">
                        Interested in our Services or any Questions?
                    </h1>
                </header>

                <p>
                    If you are interested in our services, any questions, making an appointment or for any other reason we would like to hear from you.
                    Contact us using the below form and we
                    will answer as soon as we can.
                    Thank you!
                </p>

                <p>
                    If you have questions about our courses or if you would like to buy a course then please give the title of
                    the course as the Subject of your message. The same thing goes about our services.
                </p>

                <p className="quote">
                    Our online classes are also available on weekend!
                </p>

                <div className="iframe-holder">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSfFsq95D2zcepfHVazcV8qPXGtxNT1mk7ll65Dw_iyYGFxIJg/viewform?embedded=true"
                        frameBorder="0" marginHeight="0" marginWidth="0" title="contact form" id="contact-form-ifr">Loading…</iframe>

                    <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2509.686622037871!2d3.7146328760965788!3d51.021938046052355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3732c1c6630cb%3A0x3e20d803d2474149!2sAqyanoos!5e0!3m2!1sen!2sbe!4v1716200638652!5m2!1sen!2sbe" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade" id="location-map"></iframe>
                </div>

            </div>

            <Footer name="dream-board" page="contact" />
        </>
    );
}

export default Contact;