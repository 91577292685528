import TopNav from "./TopNav";
import Footer from "./Footer";
import { copyToClipboardWeb, setPageData } from "../Libs";
import { Link } from "react-router-dom";

export default function MongoDbWebApi(props) {

    setPageData("Integrate MongoDB in ASP .Net Core Web API",
        "how to integrate mongodb in asp .net core web api", "Learn how to integrate mongodb in asp .net core web api step by step with source code and video.",
        "mongodb, web api, mongodb and web api, api request from asp net core to mongodb, restful api asp net core and mongodb"
    );

    const middleware = `
    // Add the following code right below the comment that says // Add services to the container."
    // DatabaseSettings is the model for the Database object inside "appsettings.json"
    // ConnectionStrings is the name of the object inside "appsettings.json" for MongoDB
    // StudentServices is the class with all the services to communicate with MongoDB
   // In case there are errors: Manually import the DatabaseSettings and StudenServices(The naming is because my YouTube tutorial was about Student management)

builder.Services.Configure<DatabaseSettings>(
                builder.Configuration.GetSection("ConnectionStrings"));

builder.Services.AddSingleton<StudentServices>();
`;

    const dbSettings = `
// In my case the Connection string is "Connection", 
// the Database name is "DatabaseName", 
// and the Collection name is CollectionName. You can name them whatever you like.

"ConnectionStrings": {
    "CollectionName": "students",
    "DatabaseName": "aqyanoosDB",
    "Connection": "mongodb+srv://school1:<password>@school-cluster.3wo8xzy.mongodb.net/?retryWrites=true&w=majority"
  }
`;

    const database_model = `
    namespace WebApiMongoDB.Data
{
    public class DatabaseSettings
    {
        public string CollectionName { get; set; }
        public string DatabaseName { get; set; }
        public string Connection { get; set; }
    }
}
    `;

    const student = `

using MongoDB.Bson;
using MongoDB.Bson.Serialization.Attributes;

namespace WebApiMongoDB.Models
{
    [BsonIgnoreExtraElements]
    public class Student
    {
        [BsonId]
        [BsonRepresentation(BsonType.ObjectId)]
        public string Id { get; set; } = string.Empty;

        [BsonElement("firstname")]
        public string FirstName { get; set; } = "Student First Name";

        [BsonElement("lastname")]
        public string LastName { get; set; } = "Student Last Name";

        [BsonElement("department")]
        public string Department { get; set; } = "Department";

        [BsonElement("class")]
        public string ClassName { get; set; } = "Class Name";

        [BsonElement("gender")]
        public byte Gender { get; set; } = 1;

        [BsonElement("birthday")]
        public DateTime DateOfBirth { get; set; }

        [BsonElement("age")]
        public int Age { get; set; }

        [BsonElement("graduated")]
        public bool IsGraduated { get; set; }
    }
}

    `;

    const studentService = `
    using Microsoft.Extensions.Options;
using MongoDB.Driver;
using WebApiMongoDB.Data;
using WebApiMongoDB.Models;

namespace WebApiMongoDB.Services
{
    public class StudentServices
    {
        private readonly IMongoCollection<Student> _studentCollection;

        public StudentServices(IOptions<DatabaseSettings> settings)
        {
            var mongoClient = new MongoClient(settings.Value.Connection);
            var mongoDb = mongoClient.GetDatabase(settings.Value.DatabaseName);
            _studentCollection = mongoDb.GetCollection<Student>(settings.Value.CollectionName);
        }

        // get all students
        public async Task<List<Student>> GetAsync() => await _studentCollection.Find(_ => true).ToListAsync();
        

        // get student by id
        public async Task<Student> GetAsync(string id) =>
            await _studentCollection.Find(x => x.Id == id).FirstOrDefaultAsync();

        // add new student 
        public async Task CreateAsync(Student newStudent) =>
            await _studentCollection.InsertOneAsync(newStudent);

        // update student

        public async Task UpdateAsync(string id, Student updateStudent) =>
            await _studentCollection.ReplaceOneAsync(x=> x.Id == id, updateStudent);

        // delete student
        public async Task RemoveAsync(string id)=>
            await _studentCollection.DeleteOneAsync(x=> x.Id == id);
    }
}

    
    `;

    const StudentController = `
    using Microsoft.AspNetCore.Mvc;
using WebApiMongoDB.Models;
using WebApiMongoDB.Services;

// For more information on enabling Web API for empty projects, visit https://go.microsoft.com/fwlink/?LinkID=397860

namespace WebApiMongoDB.Controllers
{
    [Route("api/student")]
    [ApiController]
    public class StudentController : ControllerBase
    {
        private readonly StudentServices _studentServices;

        public StudentController(StudentServices studentServices) {
            _studentServices = studentServices;
        }


        // GET: api/student
        [HttpGet]
        public async Task<List<Student>> Get() => await _studentServices.GetAsync();

        // GET api/student/64a51019c925955cfda51194
        [HttpGet("{id:length(24)}")]
        public async Task<ActionResult<Student>> Get(string id)
        {
            Student student = await _studentServices.GetAsync(id);
            if(student == null)
            {
                return NotFound();
            }

            return student;
        }

        // POST api/student
        [HttpPost]
        public async Task<ActionResult<Student>> Post(Student newStudent)
        {
            await _studentServices.CreateAsync(newStudent);
            return CreatedAtAction(nameof(Get), new {id = newStudent.Id}, newStudent);
        }

        // PUT api/student/64a51019c925955cfda51194
        [HttpPut("{id:length(24)}")]
        public async Task<ActionResult> Put(string id, Student updateStudent)
        {
            Student student = await _studentServices.GetAsync(id);
            if(student == null)
            {
                return NotFound("There is no student with this id: "+ id);
            }

            updateStudent.Id = student.Id;

            await _studentServices.UpdateAsync(id, updateStudent);

            return Ok("Updated Successfully");
        }

        // DELETE api/student/64a51019c925955cfda51194
        [HttpDelete("{id:length(24)}")]
        public async Task<ActionResult> Delete(string id)
        {
            Student student = await _studentServices.GetAsync(id);
            if (student == null)
            {
                return NotFound("There is no student with this id: " + id);
            }

            await _studentServices.RemoveAsync(id);

            return Ok("Deleted Successfully");
        }
    }
}

    `;


    return (
        <div>
            <TopNav cls="" />

            <header className="page-header">
                <h3 >Learn How to Integrate MongoDB in ASP .Net Core Web API</h3>
            </header>

            <div className="back-btn">
                <Link to="/tutorials?name=2" className="link">Back to the List</Link> <br />

                <p>
                    <mark>I also have a full course on YouTube about integrating MongoDB in ASP .Net Core Web API.
                        The course is a full stack CRUD Application Project with React.js, MongoDB and Web API so you learn React and Web API as well.
                    </mark>
                </p>

                <a href="https://youtu.be/YkLyu8bZwjc" className="link" target="_blank" rel="noreferrer noopener">Watch The Full Course Here</a>

            </div>
            <section id="main" className="source-codes tutorials">
                <article className="">
                    <section>
                        <h5>In just 9 steps you can configure and setup Web API for making successful
                            API calls to MongoDB Cloud Database:</h5>

                        <ol>
                            <li>Configure MongoDB Cluster</li>
                            <ol>
                                <li>Create a new Project if you do not have any</li>
                                <li>Click on the project that you want to connect to Web API then create a cluster
                                    by clicking on The big button "Build a Database":
                                </li>
                                <ol>
                                    <li>Select the correct options</li>
                                    <li>Select the correct provider</li>
                                    <li>Select the correct region</li>
                                    <li>Give a good Cluster name</li>
                                    <li>Give a good username and password</li>
                                    <li>Select the correct environment type</li>
                                    <li>Add the correct IP address. It also automatically detects the IP address of
                                        the machine with which you configure MongoDB But if you use the MongoDB in another machine
                                        then you have to add the IP address of that machine as well.
                                    </li>
                                </ol>
                                <li>Click on the "Connect" button inside your MongoDB Cluster to configure the MongoDB Cluster</li>
                                <ol>
                                    <li>Select the driver(C# .Net)</li>
                                    <li>Select the latest version</li>
                                    <li>Copy the connection string and replace the "&lt;password&gt;" with the actual user Password</li>
                                </ol>
                                <li>Create Database</li>
                                <ol>
                                    <li>Click on the tab button "Collections"</li>
                                    <li>Click on the button "Add My Own Data"</li>
                                    <li>Give a Database name and Collection name (You need these names later when you setup Web API)</li>
                                    <li>When you have Database and collection, you can add fields to your collecion </li>
                                    <li>By Default your collection has only on field(that is _id)</li>
                                    <li>Add new fields by clicking on "Insert Document"</li>
                                    <li>Choose the correct type for your fields</li>
                                </ol>
                            </ol>
                            <li>Now you are done in MongoDB, let's jump to Visual Studio</li>
                            <li>Create a new project if you do not have any(it can be only Web API project or combination of
                                Web API and other types like MVC or React.js frontend,...)
                            </li>
                            <li>Add a new object inside "appsettings.json" to store the MongoDB data: <button className="copy-btn" onClick={() => { copyToClipboardWeb(dbSettings); }}>Copy</button> <br /> <br />
                                <textarea className="w-100 code-holder" readOnly defaultValue={dbSettings}></textarea>
                            </li>

                            <li>
                                Add middleware for mongodb in "Program.cs": <button className="copy-btn" onClick={() => { copyToClipboardWeb(middleware); }}>Copy</button><br /> <br />
                                <textarea className="w-100 code-holder" readOnly defaultValue={middleware}></textarea>
                            </li>

                            <li>
                                The Code For "DatabaseSettings.cs": <button className="copy-btn" onClick={() => { copyToClipboardWeb(database_model); }}>Copy</button> <br /> <br />
                                <textarea className="w-100 code-holder" readOnly defaultValue={database_model}></textarea>

                            </li>

                            <li>
                                The Code For "Student.cs". Student is for example the name of your collection inside MongoDB and based on
                                your collection you need to create a model in Web API: <button className="copy-btn" onClick={() => { copyToClipboardWeb(student); }}>Copy</button> <br /> <br />
                                <textarea className="w-100 code-holder" readOnly defaultValue={student}></textarea>

                            </li>

                            <li>
                                The Code For "StudentServices.cs". Student is for example the name of your collection inside MongoDB.
                                It is best practice to keep everything separated(one service for one collection): <button className="copy-btn" onClick={() => { copyToClipboardWeb(studentService); }}>Copy</button> <br /> <br />
                                <textarea className="w-100 code-holder" readOnly defaultValue={studentService}></textarea>

                            </li>

                            <li>
                                The Code For "StudentController.cs". Student is for example the name of your collection inside MongoDB.
                                It is best practice to keep everything separated(one controller for one collection): <button className="copy-btn" onClick={() => { copyToClipboardWeb(StudentController); }}>Copy</button> <br /> <br />
                                <textarea className="w-100 code-holder" readOnly defaultValue={StudentController}></textarea>

                            </li>

                        </ol>
                    </section>

                </article>
            </section>

            <Footer name="dream-board" />
        </div>
    );
}