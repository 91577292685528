
// const offlineContent = require("./offline-content");
const { sideMenuContent, topMenuContent, footerContent, svgLinkIcon } = require("./offline-content");

const testBtn = document.getElementById("dd45");

if (testBtn) {
    testBtn.onclick = function () {
        console.log("Clickkkkkkkkkkkkkkkkkkkkkkkk");
    };
}

const links = document.querySelectorAll("a[href^='#'")
if(links) {
    // console.log("linsk: ", links)
    links.forEach(l => {
        l.onclick = function() {
            document.querySelector("html").scrollTop = 0
        }
    })
}

const linkIcons = document.querySelectorAll(".link-icon");
if (linkIcons) {
    linkIcons.forEach(el => {
        el.innerHTML = svgLinkIcon;
    });
}

const sideMenuEl = document.querySelector(".menu-wrapper");
const topMenuEl = document.querySelector(".main-nav");
const pageMainFooterEl = document.querySelector(".page-main-footer");


if (sideMenuEl) {
    sideMenuEl.innerHTML = sideMenuContent;

    sideMenuEl.addEventListener("click", function (e) {
        // console.log("Target: ", e.target);
        if (e.target.className.includes("menu-wrapper open")) {
            e.target.classList.remove("open");
        }
    });
}

if (topMenuEl) {
    topMenuEl.innerHTML = topMenuContent;
}

if (pageMainFooterEl) {
    pageMainFooterEl.innerHTML = footerContent;
}

function aqyanoosCustomAlert(title, description) {
    if (document.querySelector('.custom-alert')) {
        document.querySelector('.custom-alert').remove();
    }

    const secEl = document.createElement('section');
    secEl.className = "custom-alert";
    secEl.innerHTML = `
    <section class="ca-container">
            <div class="ca-title">${title}</div>
            <hr>
            <div class="ca-description">${description}</div>
            
            <div class="ca-ok" onclick="document.querySelector('.custom-alert').remove()">OK</div>
        </section>
    `;

    document.body.appendChild(secEl);
}

// for online text editor
let onlineTE_FN = '';
const onlineTeOpen = document.getElementById("online-te-open");
const onlineTeSave = document.getElementById("online-te-save");
const onlineTeSaveAs = document.getElementById("online-te-save-as"); // save as
const onlineTeDisplayFN = document.querySelectorAll(".online-te-file-name-display");
const content_ = document.getElementById('content');
const allSettingsInputs = document.querySelectorAll(".te-style-form input");
const allSettingsSelects = document.querySelectorAll(".te-style-form select");
const closeSettingsBtn = document.getElementById("te-settings-close");
const cancelSettingsBtn = document.getElementById("te-settings-cancel");
const resetSettingsBtn = document.getElementById("te-settings-reset");
const openSettingsBtn = document.querySelector(".settings-trigger");

if (onlineTeOpen) {
    onlineTeOpen.onclick = readFile;
}

function readFile(e) {
    const openFileInput = document.getElementById("openFile");

    openFileInput.click();

    openFileInput.addEventListener('change', function (e) {
        const file_ = e.target.files[0];

        onlineTE_FN = file_.name;
        onlineTeDisplayFN.forEach(e => {
            e.innerHTML = onlineTE_FN;
        });
        const reader_ = new FileReader();
        // you can use 'load', 'loadend' as the event type
        reader_.addEventListener('loadend', function (event) {
            content_.value = event.target.result;
            localStorage.setItem("te_content", event.target.result);
        });

        reader_.readAsText(file_);
    });
}

if (onlineTeSave) {
    onlineTeSave.onclick = saveFile;
}

const supportedExtensions = {
    "txt": "text/plain",
    "java": "application/java",
    "js": "apllication/javascript",
    "json": "application/json",
    "cpp": "application/cpp",
    "css": "text/css",
    "html": "text/html",
    "cshtml": "text/cshtml",
    "csv": "text/csv",
    "php": "application/x-httpd-php",
    "ts": "application/typescript",
};

function saveFile() {
    if (!onlineTE_FN) {
        newFileName("save");
    }
    debugger;
    let extension_ = "txt";
    const fileNameArray = onlineTE_FN.split(".");
    if (fileNameArray.length > 1 && supportedExtensions[fileNameArray[fileNameArray.length - 1]]) {
        extension_ = fileNameArray[fileNameArray.length - 1];
    }

    // const type_ = supportedExtensions[extension_] ? supportedExtensions[extension_] : 'text/plain';
    const contentOfFile = content_.value;
    const blob_ = new Blob([contentOfFile], { type: supportedExtensions[extension_] });
    const url_ = URL.createObjectURL(blob_);
    const aEl = document.createElement('a');
    aEl.href = url_;
    aEl.download = onlineTE_FN;
    aEl.click();
}

if (onlineTeSaveAs) {
    onlineTeSaveAs.onclick = saveAs_;
}

function newFileName(origin_) {
    const name = promptResult(origin_);
    if (name) {
        onlineTE_FN = name.includes(".") ? name : name + ".txt";
        onlineTeDisplayFN.forEach(e => {
            e.innerHTML = onlineTE_FN;
        });
    }

    if (origin_ === "save-as" && name) {
        saveFile();
    }

}

function saveAs_() {
    newFileName("save-as");
}

function promptResult(origin_) {
    // Todo: check if the user has provided any extension for the file or not
    let msg = "Please give a name for the new file and also an extension for it: ";
    let userInput = window.prompt(msg);
    if (userInput) {
        return userInput;
    } else {
        const askAgian = window.confirm("You have not given any name for the new file. Would you like to give a name and an extension?");
        if (askAgian) {
            userInput = window.prompt(msg);
            if (userInput) {
                return userInput;
            }
        }
    }

    if (origin_ === "save-as") {
        aqyanoosCustomAlert("Warning", "Could not save a copy of your file because you did not provide any name. Try again.");
        return null;
    } else {
        aqyanoosCustomAlert("Warning", "You did not give any name and extension for your file, so we gave the default name(aqyanoos.com.txt)");
        return 'aqyanoos.com.txt';
    }
}

// for backup feature and settings
const defaultSettings = {
    textStyle: "normal",
    textColor: "#000",
    textSize: 16,
    backgroundColor: "#d3d6d6",
    padding: {
        top: 10,
        rightLeft: 10,
        bottom: 40
    },
    fontFamily: "Arial",
    customFontFamily: null,
    textDirection: "ltr",
    textAlign: "left",
    lineHeight: 27,
    letterSpacing: 1
};

// on page load

if (content_) {
    const teSettings = localStorage.getItem("te_settings");
    if (teSettings) {
        applySettings(JSON.parse(teSettings));
    } else {
        applySettings(defaultSettings);
    }

    content_.oninput = function (e) {
        localStorage.setItem("te_content", e.target.value);
    };

    content_.value = localStorage.getItem("te_content");



    closeSettingsBtn.onclick = function (e) {
        // set settings, store in local storage then apply
        e.preventDefault();

        const form_ = document.getElementById("te-style-form"), submitter_ = e.target;

        const fD = new FormData(form_, submitter_);

        // const dataToSend = {};

        for (const [key, value] of fD) {
            // dataToSend[key] = value;
            setSettings(key, value);
        }

        //  console.log("new settings: ", dataToSend);
        // console.log("old settings: ", defaultSettings);

        localStorage.setItem("te_settings", JSON.stringify(defaultSettings));
        applySettings(defaultSettings);
        document.querySelector(".te-settings").classList.add("hidden");
    };

    openSettingsBtn.onclick = function () {
        // populate settings to UI
        let backupSettings = localStorage.getItem("te_settings");
        if (backupSettings) {
            backupSettings = JSON.parse(backupSettings);
        } else {
            backupSettings = defaultSettings;
        }

        const generalInputNames = ["textColor", "backgroundColor", "textSize", "fontFamily", "customFontFamily"];
        allSettingsInputs.forEach(input => {

            if (backupSettings.textStyle === input.id ||
                backupSettings.textDirection === input.id ||
                backupSettings.textAlign === input.id) {
                input.checked = true;
            }

            if (input.name === "pt") {
                input.value = backupSettings.padding.top;
            }

            if (input.name === "pb") {
                input.value = backupSettings.padding.bottom;
            }

            if (input.name === "prl") {
                input.value = backupSettings.padding.rightLeft;
            }

            if (generalInputNames.includes(input.name)) {
                input.value = backupSettings[input.name];
            }

        });

        allSettingsSelects.forEach(selectEl => {
            selectEl.value = backupSettings[selectEl.name];
        });


        document.querySelector(".te-settings").classList.remove("hidden");
    };

    cancelSettingsBtn.onclick = function (e) {
        document.querySelector(".te-settings").classList.add("hidden");
    };

    resetSettingsBtn.onclick = function (e) {
        const confirm_ = window.confirm("Are you sure you want to reset the settings?");
        if (confirm_) {
            localStorage.removeItem("te_settings");
            window.location.reload();
        }
    };
}


function applySettings(s_) {
    let fontFamily = s_.customFontFamily ? s_.customFontFamily : s_.fontFamily;
    // if (fontFamily.trim().includes(" ")) {
    //     fontFamily = fontFamily.replaceAll(" ", "-");
    // }

    const fontWeight = s_.textStyle === "bold" ? "bold" : "normal";
    content_.style = "color: " + s_.textColor + ";font-size: " + s_.textSize + "px;background:" + s_.backgroundColor +
        ";font-style:" + s_.textStyle + ";padding: " + s_.padding.top + "px " + s_.padding.rightLeft + "px " + s_.padding.bottom + "px " + s_.padding.rightLeft + "px" +
        ";direction: " + s_.textDirection + ";text-align: " + s_.textAlign + ";font-family: " + fontFamily + ";font-weight:" + fontWeight +
        ";line-height: " + s_.lineHeight + "px;letter-spacing: " + s_.letterSpacing + "px;";
}

function setSettings(key_, value_) {

    switch (key_) {
        case "pt":
            defaultSettings.padding.top = value_;
            break;

        case "pb":
            defaultSettings.padding.bottom = value_;
            break;

        case "prl":
            defaultSettings.padding.rightLeft = value_;
            break;
        default:
            defaultSettings[key_] = value_;
            break;
    }

    // console.log("key_: ", key_, " , value_: ", value_, " default: ", defaultSettings);
}


// handle welcome popup
/*  +
 +
function welcomeAlert() {
    const isShown = window.localStorage.getItem("ote_shown");
    let display_ = false;
    // && : if it finds one false then it stops
    // || : if it finds one true then it stops

    if (!isShown || (isShown && isShown === "3")) {
        display_ = true;
    }

    if (display_) {
        window.localStorage.setItem("ote_shown", "5");
        const welcomeAlertWrapper = document.querySelector(".welcome-alert-wrapper");
        if (welcomeAlertWrapper) {
            welcomeAlertWrapper.innerHTML = `
            <section class="welcome-alert">
            <header><h1>Online Text or Code Editor - Notepad</h1></header>
            <p>
                Free online Text or Code Editor or Notepad without any ads. Very clean user interface. This is the Text
                Editor that you are looking for.
                Thank you for visiting our website and Enjoy using this Notepad. There are no Ads around here Only for
                you
                to have a good experience. <br> Have great times.
            </p>
            <div class="close-button" onclick="document.querySelector('.welcome-alert').remove()">Close</div>
        </section>
            `;
        }
    }
}

welcomeAlert();

///////// oninput event settings
// allSettingsInputs.forEach(input => {
    //     input.oninput = function (e) {
    //         if (e.target.name === "textStyle" && e.target.checked) {
    //             setSettings(e.target.name, e.target.value);
    //         } else if (e.target.name === "textDirection" && e.target.checked) {
    //             setSettings(e.target.name, e.target.value);
    //         } else if (e.target.name === "textAlign" && e.target.checked) {
    //             setSettings(e.target.name, e.target.value);
    //         } else {
    //             setSettings(e.target.name, e.target.value);
    //         }
    //     };
    // });

    // allSettingsSelects.forEach(selectEl => {
    //     selectEl.oninput = function (e) {
    //         setSettings(e.target.name, e.target.value);
    //     };
    // });
*/