
import TopNav from "./TopNav";
import Footer from "./Footer";
import { playServiceImgCss, jumpToSec, ServicePrice, localCurrency } from "../Libs";
import "./Services.css";
import { useEffect } from "react";
//  import { Link } from "react-router-dom";

function Services() {
    const currency = localCurrency();

    const learnSmartphones = new ServicePrice(49.99, 70);
    const officeOnline = new ServicePrice(79.99, 55);
    const googleDrive = new ServicePrice(29.99, 75);
    const officePack = new ServicePrice(119.79, 55);
    const computerBasics = new ServicePrice(89.50, 75);
    const msOneDrive = new ServicePrice(39.75, 70);
    const webDevelopmentCourse = new ServicePrice(99.99, 65);

    useEffect(() => {
        jumpToSec();
        document.title = "A-Z IT Services"
    }, []);

    return (
        <>
            <TopNav cls="Services_page" />

            <section id="services">

                <section>
                    <h2>IT Services (A - Z)</h2>
                    <p>
                        We provide full services for web development from listening to your story to publishing your website.
                        Updating, upgrading, maintaining your existing web apps and taking care of the security.
                        We provide the same services for mobile apps. We have also all kinds of computer, web and software development courses.
                        <br /> We do it for you or we teach you how to do it yourself.
                    </p>

                    <p>
                        We come to you, listen to your story and do more than our best to provide you the best services possible.
                    </p>

                    <p className="quote">
                        For now we provide services in Dutch and in English. We have plan to add French and German languages in the future.
                    </p>

                    <p className="quote green">
                        Our prices are fair and affordable by everyone.
                    </p>
                </section>
                {/* summary section */}


                {/* All Services and Courses in Details */}

                <article>
                    <hr />
                    <section id="web-development-services">
                        <h3 className="txt-c">Our Web Development and Programming Services</h3>
                        <hr />
                        <section className="w-100 service-details flex space-around" id="create-new-website">
                            <div className=" service-description">
                                <div className="title" >Build New Website or Web Application</div>
                                <section>
                                    <p>
                                        For a very fair price we can build you any types of applications from a very basic static website
                                        up to and including system management applications for managing your business, analyzing your data,
                                        controlling the flow of your business,...and you name it. <br /> <strong>We build Fully Responsive User Friendly Multilingual applications(Basic SEO included).</strong>
                                    </p>

                                    <p>
                                        If you need a static informative website just to showcase your business, provide information to
                                        the visitor of your website, provide information about your business, keep your customers up to date
                                        with your business, promote your products,... we will do it for you in less than a week (depending on the size of the App).
                                    </p>

                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/build-your-first-website.png" alt="build-make-create-website" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex-reverse space-around" id="update-your-website">
                            <div className=" service-description">
                                <div className="title" >Updating Your Website or Web Applications</div>
                                <section>
                                    <div>Why is it necessary and important to update your applications on time?...keep reading.</div>
                                    <ul>
                                        <li>Dependency update: you should keep your web apps up to date otherwise you risk security
                                            problems. Hackers can hack the old versions of your application's dependencies because
                                            they are not maintained anymore and nobody is responsible for that. If you want that your applications and system work efficient and
                                            are secure and fast Then you have to update the dependencies regularly.
                                        </li>
                                        <li>
                                            User Interface update: It is very important that the visitors of your website
                                            see the correct information such as working hours, the address / location, the contact options(email address, phone number,...).
                                            If you want to update th style and look of your website, the Information on the User Interface,...
                                            We can do it for you, using the most recent web technologies for a very fair price.
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/how-to-update-an-old-website.png" alt="update-dependencies-user-interface-website" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex space-around" id="upgrade-your-website">

                            <div className=" service-description">
                                <div className="title">Upgrading Your Website or Web Applications</div>
                                <section>
                                    <div>Upgrading your website is even more important than Updating. Why? keep reading.</div>
                                    <p>
                                        If your application's framework / dependencies are too old and is not maintained
                                        Then you have to upgrade to different technologies otherwise it is too risky and dangerous.
                                    </p>
                                    <ul>
                                        <li>
                                            Back-end upgrade: If the back-end technologies of your application is too old Then it
                                            is the time to upgrade to the recent technologies for better performance, security and efficiency,...
                                        </li>
                                        <li>
                                            User Interface upgrade: If the Front-end technologies of your application is too old then it is
                                            much easier for hackers to inject malicious codes to attack your system so you have to upgrade
                                            immediately or if you want to switch the technology used in the UI of your applications,
                                            We can upgrade it to the most recent web UI technologies.
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/how-to-upgrade-old-websites.png" alt="how-to-upgrade-old-websites" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex-reverse space-around" id="setup-configure-computers-servers">
                            <div className="  service-description">
                                <div className="title">Setup and Configure Your Computers / Servers</div>
                                <section>
                                    <div>
                                        <div>Do you need professionals to setup your business tools and servers?
                                            We can setup and configure your computers and servers. Our professionals make your machines ready to use:</div>
                                        <ul>
                                            <li>Web Servers</li>
                                            <li>Database Servers</li>
                                            <li>Domain Servers</li>
                                            <li>File Servers</li>
                                            <li>...</li>
                                        </ul>
                                    </div>
                                </section>
                            </div>

                            <div className="  service-picture">
                                <img src="/images/setup-configure-servers.png" alt="setup configure servers" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex space-around" id="teaching-ICT-staff-web-development">

                            <div className=" service-description">
                                <div className="title" >Teaching Your IT Staff The Recent Technologies</div>
                                <section>
                                    <p>
                                        We are also here to teach your IT staff to do it All by themselves.
                                        We create private course specific for your company, for your team, for your applications's technologies....
                                    </p>

                                    <p>
                                        Or if you want to switch technology, we can create private course about the most recent technologies so that
                                        your team can do everything by themselves.
                                        <strong>We know how to teach effective and efficient.</strong>

                                    </p>

                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/learn-web-development-special-course.png" alt="" />
                            </div>
                        </section>
                    </section>
                    <hr />
                    <section id="courses-trainings">
                        <h3 className="txt-c">Our Course and Training Programs</h3>
                        <hr />
                        <section className="w-100 service-details flex space-around" id="learn-smartphones">
                            <div className=" service-description">
                                <div className="title">Find Your Way Around Your Smartphone</div>
                                <section>
                                    <div>
                                        If you have trouble using your phone, if you are not familiar with the settings and configurations, ...
                                        If you do not know enough about your phone Then we can teach you from very basics to very advanced So
                                        that you solve all your smartphone problems yourself and find your way around your smartphone.
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {learnSmartphones.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{learnSmartphones.newPrice()} </span>
                                        <span className="discount">{learnSmartphones.discount}% OFF</span>
                                    </div>

                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/smartphone-tutorial-for-beginners.png" alt="learn-smartphone-tutorial-for-beginners" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex-reverse space-around web-development-course" id="web-development-course">
                            <div className=" service-description">
                                <div className="title">Teaching Web Development(Front-end and or Back-end)</div>
                                <section>
                                    <div>
                                        We can teach you to become a professional Web Developer with the knowledge of the Newest
                                        Technologies like React.js, Angular.js, Vue.js, JavaScript / TypeScript , C++, C#, Python,
                                        Visual Studio, .Net, SQL Server, Deployment, Hosting, Web Server, Web API, Node.js, Pure HTML, CSS / SCSS,
                                        Visual Studio Code, Android Studio, Java, REST API, Git, GitHub, ChatGPT, Command-line,
                                        and whatever you would like to learn in the world of web technologies. This course is minimum 6 months and Maximum 9 months.
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {webDevelopmentCourse.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{webDevelopmentCourse.newPrice()} </span>
                                        <span className="discount">{webDevelopmentCourse.discount}% OFF</span>
                                    </div>
                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/web-development-course-online-free.png" alt="" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex space-around " id="microsoft-office-online-course-for-free">
                            <div className=" service-description">
                                <div className="title">Teaching The Online version of Microsoft Office</div>
                                <section>
                                    <div>
                                        If You would like to learn the current version of Microsoft Office inside OneDrive,
                                        We can teach you that very effectively and efficiently in a very short time.
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {officeOnline.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{officeOnline.newPrice()} </span>
                                        <span className="discount">{officeOnline.discount}% OFF</span>
                                    </div>
                                </section>
                            </div>
                            <div className=" service-picture">
                                <img src="/images/ms-office-online.png" alt="learn-microsoft-office-online-course-for-free" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex-reverse space-around" id="on-demand-courses">
                            <div className="  service-description">
                                <div className="title">On-demand Courses</div>
                                <section>
                                    <div>
                                        <div>If we get requests from our community channels like YouTube, TikTok, Instagram or Twitter then
                                            We will create courses about:</div>
                                        <ul>
                                            <li>Programming and Coding</li>
                                            <li>Web Development</li>
                                            <li>Android App Development</li>
                                            <li>Windows Operating System</li>
                                            <li>Linux Operating System</li>
                                            <li>Android Smartphones</li>
                                            <li>Android Tablets</li>
                                            <li>Apple MacOS</li>
                                            <li>Apple iPhone</li>
                                            <li>Apple iPad</li>
                                        </ul>
                                    </div>
                                </section>
                            </div>
                            <div className="  service-picture">
                                <img src="/images/on-demand-course-information-technology.png" alt="On-demand courses" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex space-around" id="google-drive-course-online-free">
                            <div className="  service-description">
                                <div className="title" id="learn-google-drive">Teaching Google-Drive</div>
                                <section>
                                    <div>
                                        Google-Drive is a cloud-based service that offers way more than people know.
                                        It is not only a storage service but also it has all the Office Softwares that
                                        you can use absolutely Free. We teach you Google Drive from Zero to Hero very effective
                                        and efficient.
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {googleDrive.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{googleDrive.newPrice()} </span>
                                        <span className="discount">{googleDrive.discount}% OFF</span>
                                    </div>
                                </section>
                            </div>
                            <div className="  service-picture">
                                <img src="/images/google-drive-full-coursetutorial.png" alt="learn Google Drive" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex-reverse space-around" id="microsoft-office-pack-tutorial-for-free">
                            <div className="  service-description">
                                <div className="title" id="learn-ms-office-pack">Teaching the Recent version of Microsoft Office Pack</div>
                                <section>
                                    <div>
                                        If would like to learn the most recent version of Microsoft Office on Your Computer
                                        then we teach you them in a very short time and efficient way.
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {officePack.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{officePack.newPrice()} </span>
                                        <span className="discount">{officePack.discount}% OFF</span>
                                    </div>
                                </section>
                            </div>
                            <div className="  service-picture">
                                <img src="/images/microsoft-office-pack-offline.png" alt="learn the recent version of Microsoft Office" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex space-around learn-computer-basics" id="learn-computer-basics">
                            <div className="  service-description">
                                <div className="title" >Find Your Way Around Your Computer</div>
                                <section>
                                    <div>
                                        If would like to know All About Your Computer, Explore All the options and All the tools
                                        And of course Learn how to use them then you are at the right place. Contact us and we teach
                                        you all of them very efficient and effective so that you do not have to jump to Browser for
                                        every small issue or rush to Computer repair store and Pay a lot of money. Besides, Windows has
                                        a lot of tools and softwares that are by default installed on your computer that you still
                                        do not know.
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {computerBasics.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{computerBasics.newPrice()} </span>
                                        <span className="discount">{computerBasics.discount}% OFF</span>
                                    </div>
                                </section>
                            </div>
                            <div className="  service-picture">
                                <div className="service-img-list">
                                    <img className="demo-img img-0" src="/images/learn-computer-basics-001.png" alt="Learn Windows pc basics" />
                                    <img className="demo-img img-1 hidden" src="/images/learn-computer-basics-002.png" alt="Windows pc basics tutorial" />
                                    <img className="demo-img img-2 hidden" src="/images/learn-computer-basics-003.png" alt="Windows basics course for beginners" />
                                    <img className="demo-img img-3 hidden" src="/images/learn-computer-basics-004.png" alt="Computer course online for free" />
                                    <img className="demo-img img-4 hidden" src="/images/learn-computer-basics-005.png" alt="Computer basics for beginners" />
                                </div>
                                <div className="service-img-ctrl">
                                    <div className="service-img-ctrl-b disabled" onClick={(e) => { playServiceImgCss(e, "B", "learn-computer-basics"); }} cid="1230">
                                        <div></div>
                                    </div>

                                    <div className="service-img-ctrl-f" onClick={(e) => { playServiceImgCss(e, "F", "learn-computer-basics"); }} cid="1231">
                                        <div></div>
                                    </div>
                                </div>
                                <div className="service-img-counter">
                                    <div className="xxx_0 active"></div>
                                    <div className="xxx_1"></div>
                                    <div className="xxx_2"></div>
                                    <div className="xxx_3"></div>
                                    <div className="xxx_4"></div>
                                </div>
                            </div>
                        </section>

                        <section className="w-100 service-details flex-reverse space-around" id="microsoft-onedrive-course-for-free">
                            <div className="  service-description">
                                <div className="title" >Teaching Microsoft OneDrive</div>
                                <section>
                                    <div>
                                        Microsoft OneDrive is a Cloud-based service that has all the features and options for
                                        office work or administrative work. There You have All Office Tools or Software, cloud storage,
                                        sharing and Collaborating features,...and much more. We can teach you all about it!
                                    </div>
                                    <div className="service-price">
                                        <strong>Price: </strong>
                                        <span className="old-price"> {msOneDrive.old}</span>
                                        <span className="new-price"><span className="currency">{currency}</span>{msOneDrive.newPrice()} </span>
                                        <span className="discount">{msOneDrive.discount}% OFF</span>
                                    </div>
                                </section>
                            </div>

                            <div className="  service-picture">
                                <img src="/images/ms-onedrive-online.png" alt="Learn All about Microsoft OneDrive" />
                            </div>
                        </section>

                        <section className="w-100 service-details flex space-around private-courses" id="private-courses-and-classes">
                            <div className="  service-description">
                                <div className="title">Private Courses</div>
                                <section>
                                    <div>
                                        <div>We can teach you in a private class or
                                            We can create private courses about:</div>
                                        <ul>
                                            <li>Programming and Coding</li>
                                            <li>Web Development</li>
                                            <li>Android App Development</li>
                                            <li>Windows Operating System</li>
                                            <li>Linux Operating System</li>
                                            <li>Android Smartphones</li>
                                            <li>Android Tablets</li>
                                            <li>Apple MacOS</li>
                                            <li>Apple iPhone</li>
                                            <li>Apple iPad</li>
                                        </ul>
                                    </div>
                                </section>
                            </div>

                            <div className="  service-picture">
                                <img src="/images/private-course-about-information-technology.png" alt="private courses" />
                            </div>
                            {/* <div className="  service-picture">
                                <div className="service-img-list">
                                    <img className="demo-img img-0" src="/images/learn-computer-basics-001.png" alt="Learn Windows pc basics" />
                                    <img className="demo-img img-1 hidden" src="/images/learn-computer-basics-002.png" alt="Learn Windows pc basics" />
                                    <img className="demo-img img-2 hidden" src="/images/learn-computer-basics-003.png" alt="Learn Windows pc basics" />
                                    <img className="demo-img img-3 hidden" src="/images/learn-computer-basics-004.png" alt="Learn Windows pc basics" />
                                    <img className="demo-img img-4 hidden" src="/images/learn-computer-basics-005.png" alt="Learn Windows pc basics" />
                                </div>
                                <div className="service-img-ctrl">
                                    <div className="service-img-ctrl-b disabled" onClick={(e) => { playServiceImgCss(e, "B", "private-courses") }}>
                                        <div></div>
                                    </div>

                                    <div className="service-img-ctrl-f" onClick={(e) => { playServiceImgCss(e, "F", "private-courses") }}>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="service-img-counter">
                                    <div className="xxx_0 active"></div>
                                    <div className="xxx_1"></div>
                                    <div className="xxx_2"></div>
                                    <div className="xxx_3"></div>
                                    <div className="xxx_4"></div>
                                </div>
                            </div>  */}
                        </section>

                    </section>
                </article>
            </section>

            <Footer name="dream-board" />
        </>
    );
}

export default Services;
