import React from 'react';
import ReactDOM from 'react-dom/client';
import './helper.css';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';

const rootElement = document.getElementById('root')


if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <HashRouter >
      <App />
    </HashRouter>
  );
}
// basename='https://aqyanoos.com'
// https://Sayed94h.github.io/aqyanoos
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)) 
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
