import TopNav from "./TopNav";
import Footer from "./Footer";
import { setPageData } from "../Libs";
import { Link } from "react-router-dom";

export default function CssSelectors(props) {

    setPageData("List Of All CSS Selectors",
        "List Of All CSS Selectors", "Learn All CSS Selectors with examples in HTML.",
        "CSS Selectors, CSS3 Selectors, what are css selectors, all css selectors, learn css selectors, list of all css selectors"
    );

    // const setContent = () =>
    // {
    //     const all_tr = document.querySelectorAll( "tr" );
    //     if ( all_tr )
    //     {
    //         for ( let i = 0; i < all_tr.length; i++ )
    //         {
    //             const td = all_tr[i].children[1];
    //             if ( td.nodeName === "TD" )
    //             {
    //                 // const text_ = td.textContent;
    //                 // td.innerHTML = `<em>${ text_ }</em>`;
    //                 // td.classList.add( "dark-gold" );
    //             }
    //         }
    //     }
    // };

    return (
        <div>
            <TopNav cls="" />

            <header className="page-header">
                <h3 >List Of All CSS Selectors</h3>
            </header>

            <div className="back-btn">
                <Link to="/tutorials?name=1" className="link">Back to the List</Link> <br />

                <p>
                    <mark>I also have a CSS full course on YouTube. In the course you learn everything about CSS And also
                        all these CSS Selectors with examples.</mark>
                </p>

                <a href="https://youtu.be/9RlYJqW7PV4" className="link" target="_blank" rel="noreferrer noopener">Watch The Full Course Here</a>
            </div>
            <section id="main" className="source-codes">
                <article className="table-holder">
                    <table className="min-w-650">
                        <thead>
                            <tr>
                                <th className="w-20">Selector Name</th>
                                <th className="w-20">Example</th>
                                <th >Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><em>.class</em></td>
                                <td className="dark-gold">.hidden</td>
                                <td>Selects all elements with <span className="blue">class="hidden"</span></td>
                            </tr>
                            <tr>
                                <td><em>.class1.class2</em></td>
                                <td className="dark-gold">.active.btn</td>
                                <td>Selects all elements with <span className="blue">class="active btn"</span></td>
                            </tr>
                            <tr>
                                <td><em>.class1 .class2</em></td>
                                <td className="dark-gold">.test-container .test-child</td>
                                <td>Selects all elements with <span className="blue">class="test-child"</span> that are child elements of <span
                                    className="blue">class="test-container"</span></td>
                            </tr>
                            <tr>
                                <td><em>#id</em></td>
                                <td className="dark-gold">#user-id</td>
                                <td>Selects the element with <span className="blue">id="user-id"</span></td>
                            </tr>
                            <tr>
                                <td><em>*</em></td>
                                <td className="dark-gold">*</td>
                                <td>Selects all elements(global or all child of specified element)</td>
                            </tr>
                            <tr>
                                <td><em>element</em></td>
                                <td className="dark-gold">section</td>
                                <td>Selects all <span className="blue">&lt;section&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>element.class</em></td>
                                <td className="dark-gold">section.first</td>
                                <td>Selects all <span className="blue">&lt;section&gt;</span> elements with <span className="blue">class="first"</span>
                                </td>
                            </tr>
                            <tr>
                                <td><em>element, element</em></td>
                                <td className="dark-gold">section, h3</td>
                                <td>Selects all <span className="blue">&lt;section&gt;</span>  elements and all <span className="blue">&lt;h3&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em></em></td>
                                <td className="bold txt-3 bdr-e-none">CSS Combinator Selectors</td>
                            </tr>
                            <tr>
                                <td><em>element element</em></td>
                                <td className="dark-gold">section h3</td>
                                <td>Selects all <span className="blue">&lt;h3&gt;</span> elements that are inside <span className="blue">&lt;section&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>element&gt;element</em></td>
                                <td className="dark-gold">section &gt; h3</td>
                                <td>Selects all <span className="blue">&lt;h3&gt;</span> elements where the parent is a <span className="blue">&lt;section&gt;</span> element</td>
                            </tr>
                            <tr>
                                <td><em>element + element</em></td>
                                <td className="dark-gold">section + div</td>
                                <td>Selects the first <span className="blue">&lt;div&gt;</span> element that is placed immediately after <span className="blue">&lt;section&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>element1 ~ element2</em></td>
                                <td className="dark-gold">div ~ p</td>
                                <td>Selects every <span className="blue">&lt;p&gt;</span> element that is preceded by a <span className="blue">&lt;div&gt;</span> element</td>
                            </tr>
                            <tr>
                                <td><em></em></td>
                                <td className="bold txt-3 bdr-e-none">CSS Attribute Selectors</td>
                            </tr>
                            <tr>
                                <td><em>[attribute]</em></td>
                                <td className="dark-gold">[target]</td>
                                <td>Selects all elements with a target attribute</td>
                            </tr>
                            <tr>
                                <td><em>[attribute=value]</em></td>
                                <td className="dark-gold">[type="text"]</td>
                                <td>Selects all elements with <span className="blue">type="text"</span></td>
                            </tr>
                            <tr>
                                <td><em>[attribute~=value]</em></td>
                                <td className="dark-gold">[temp-val~="aqyanoos"]</td>
                                <td>Selects all elements with a temp-val attribute containing the word "aqyanoos"</td>
                            </tr>
                            <tr>
                                <td><em>[attribute|=value]</em></td>
                                <td className="dark-gold">[class|="container"]</td>
                                <td>Selects all elements with a class attribute value equal to "container" or starting with "container-"</td>
                            </tr>
                            <tr>
                                <td><em>[attribute^=value]</em></td>
                                <td className="dark-gold">img[src^="nature"]</td>
                                <td>Selects every <span className="blue">&lt;img&gt;</span> element whose src attribute value begins with "nature"</td>
                            </tr>
                            <tr>
                                <td><em>[attribute$=value]</em></td>
                                <td className="dark-gold">img[src$=".png"]</td>
                                <td>Selects every <span className="blue">&lt;img&gt;</span> element whose src attribute value ends with ".png"</td>
                            </tr>
                            <tr>
                                <td><em>[attribute*=value]</em></td>
                                <td className="dark-gold">a[href*="aqyanoos"]</td>
                                <td>Selects every <span className="blue">&lt;a&gt;</span> element whose href attribute value contains the string "aqyanoos"</td>
                            </tr>
                            <tr>
                                <td><em></em></td>
                                <td className="bold txt-3 bdr-e-none">CSS Pseudo-Class Selectors</td>
                            </tr>
                            <tr>
                                <td><em>:active</em></td>
                                <td className="dark-gold">a:active</td>
                                <td>Selects the active link</td>
                            </tr>
                            <tr>
                                <td><em>:link</em></td>
                                <td className="dark-gold">a:link</td>
                                <td>Selects all unvisited links</td>
                            </tr>
                            <tr>
                                <td><em>:visited</em></td>
                                <td className="dark-gold">a:visited</td>
                                <td>Selects all visited links</td>
                            </tr>
                            <tr>
                                <td><em>:hover</em></td>
                                <td className="dark-gold">.link:hover</td>
                                <td>Selects all elements with <span className="blue">class="link"</span> on mouse over</td>
                            </tr>
                            <tr>
                                <td><em>:checked</em></td>
                                <td className="dark-gold">input:checked</td>
                                <td>Selects all checked <span className="blue">&lt;input&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>:default</em></td>
                                <td className="dark-gold">input:default</td>
                                <td>Selects all default <span className="blue">&lt;input&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>:disabled</em></td>
                                <td className="dark-gold">input:disabled</td>
                                <td>Selects all disabled <span className="blue">&lt;input&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>:empty</em></td>
                                <td className="dark-gold">div:empty</td>
                                <td>Selects all <span className="blue">&lt;div&gt;</span> element that has no children</td>
                            </tr>
                            <tr>
                                <td><em>:enabled</em></td>
                                <td className="dark-gold">input:enabled</td>
                                <td>Selects all enabled <span className="blue">&lt;input&gt;</span> elements</td>
                            </tr>
                            <tr>
                                <td><em>:first-child</em></td>
                                <td className="dark-gold">div:first-child</td>
                                <td>Selects all <span className="blue">&lt;div&gt;</span> elements that are the first child</td>
                            </tr>
                            <tr>
                                <td><em>:first-of-type</em></td>
                                <td className="dark-gold">.hdr:first-of-type</td>
                                <td>Selects all elements with <span className="blue">class="hdr"</span> that are the first element inside its parent</td>
                            </tr>
                            <tr>
                                <td><em>:focus</em></td>
                                <td className="dark-gold">input:focus</td>
                                <td>Selects the input element which has focus</td>
                            </tr>
                            <tr>
                                <td><em>:fullscreen</em></td>
                                <td className="dark-gold">:fullscreen</td>
                                <td>Selects all elements that are in full-screen mode</td>
                            </tr>
                            <tr>
                                <td><em>:in-range</em></td>
                                <td className="dark-gold">input:in-range</td>
                                <td>Selects input elements with a value within a specified range</td>
                            </tr>
                            <tr>
                                <td><em>:indeterminate</em></td>
                                <td className="dark-gold">input:indeterminate</td>
                                <td>Selects input elements that are in an indeterminate state</td>
                            </tr>
                            <tr>
                                <td><em>:invalid</em></td>
                                <td className="dark-gold">input:invalid</td>
                                <td>Selects all input elements with an invalid value</td>
                            </tr>
                            <tr>
                                <td><em>:lang(language)</em></td>
                                <td className="dark-gold">div:lang(en)</td>
                                <td>Selects every <span className="blue">&lt;div&gt;</span> element with a lang attribute equal to "en"</td>
                            </tr>
                            <tr>
                                <td><em>:last-child</em></td>
                                <td className="dark-gold">div:last-child</td>
                                <td>Selects all <span className="blue">&lt;div&gt;</span> elements that are the last child of its/their parent(s)</td>
                            </tr>
                            <tr>
                                <td><em>:last-of-type</em></td>
                                <td className="dark-gold">p:last-of-type</td>
                                <td>Selects all <span className="blue">&lt;p&gt;</span> elements that are the last <span className="blue">&lt;p&gt;</span> element of their parents</td>
                            </tr>
                            <tr>
                                <td><em>:not(selector)</em></td>
                                <td className="dark-gold">:not(p)</td>
                                <td>Selects every element that is not a <span className="blue">&lt;p&gt;</span> element</td>
                            </tr>
                            <tr>
                                <td><em>:nth-child(n)</em></td>
                                <td className="dark-gold">p:nth-child(3)</td>
                                <td>Selects all <span className="blue">&lt;p&gt;</span> elements that are the third child of their parent</td>
                            </tr>
                            <tr>
                                <td><em>:nth-last-child(n)</em></td>
                                <td className="dark-gold">div:nth-last-child(2)</td>
                                <td>Selects every <span className="blue">&lt;div&gt;</span> element that is the third child from the end</td>
                            </tr>
                            <tr>
                                <td><em>:nth-last-of-type(n)</em></td>
                                <td className="dark-gold">div:nth-last-of-type(4)</td>
                                <td>Selects every <span className="blue">&lt;div&gt;</span> element that is the fourth <span className="blue">&lt;div&gt;</span> element from the end</td>
                            </tr>
                            <tr>
                                <td><em>:nth-of-type(n)</em></td>
                                <td className="dark-gold">p:nth-of-type(3)</td>
                                <td>Selects every <span className="blue">&lt;p&gt;</span> element that is the third <span className="blue">&lt;p&gt;</span> element of its parent</td>
                            </tr>
                            <tr>
                                <td><em>:only-of-type</em></td>
                                <td className="dark-gold">p:only-of-type</td>
                                <td>Selects every <span className="blue">&lt;p&gt;</span> element that is the only <span className="blue">&lt;p&gt;</span> element of its parent</td>
                            </tr>
                            <tr>
                                <td><em>:only-child</em></td>
                                <td className="dark-gold">p:only-child</td>
                                <td>Selects every <span className="blue">&lt;p&gt;</span> element that is the only child of its parent</td>
                            </tr>
                            <tr>
                                <td><em>:optional</em></td>
                                <td className="dark-gold">input:optional</td>
                                <td>Selects input elements with no "required" attribute</td>
                            </tr>
                            <tr>
                                <td><em>:out-of-range</em></td>
                                <td className="dark-gold">input:out-of-range</td>
                                <td>Selects input elements with a value outside a specified range</td>
                            </tr>
                            <tr>
                                <td><em>:read-only</em></td>
                                <td className="dark-gold">input:read-only</td>
                                <td>Selects input elements with the "readonly" attribute specified</td>
                            </tr>
                            <tr>
                                <td><em>:read-write</em></td>
                                <td className="dark-gold">textarea:read-write</td>
                                <td>Selects textarea elements with the "readonly" attribute NOT specified</td>
                            </tr>
                            <tr>
                                <td><em>:required</em></td>
                                <td className="dark-gold">textarea:required</td>
                                <td>Selects textarea elements with the "required" attribute specified</td>
                            </tr>
                            <tr>
                                <td><em>:root</em></td>
                                <td className="dark-gold">:root</td>
                                <td>Selects the document's root element</td>
                            </tr>
                            <tr>
                                <td><em>:target</em></td>
                                <td className="dark-gold">#user-12:target </td>
                                <td>Selects the current active #user-12 element (clicked on a URL containing that anchor name)</td>
                            </tr>
                            <tr>
                                <td><em>:valid</em></td>
                                <td className="dark-gold">input:valid</td>
                                <td>Selects all input elements with a valid value</td>
                            </tr>
                            <tr>
                                <td><em></em></td>
                                <td className="bold txt-3 bdr-e-none">CSS Pseudo-Element Selectors</td>
                            </tr>
                            <tr>
                                <td><em>::after</em></td>
                                <td className="dark-gold">i::after</td>
                                <td>Insert something after the content of each <span className="blue">&lt;i&gt;</span> element</td>
                            </tr>
                            <tr>
                                <td><em>::before</em></td>
                                <td className="dark-gold">.icon::before</td>
                                <td>Insert something before&nbsp;the content of each element with the <span className="blue">class="icon"</span></td>
                            </tr>
                            <tr>
                                <td><em>::first-letter</em></td>
                                <td className="dark-gold">.speech::first-letter</td>
                                <td>Selects the first letter of every element with <span className="blue">class="speech"</span></td>
                            </tr>
                            <tr>
                                <td><em>::first-line</em></td>
                                <td className="dark-gold">div::first-line</td>
                                <td>Selects the first line of every <span className="blue">&lt;div&gt;</span> element</td>
                            </tr>
                            <tr>
                                <td><em>::marker</em></td>
                                <td className="dark-gold">::marker</td>
                                <td>Selects the markers of list items</td>
                            </tr>
                            <tr>
                                <td><em>::placeholder</em></td>
                                <td className="dark-gold">input::placeholder</td>
                                <td>Selects input elements with the "placeholder" attribute specified</td>
                            </tr>
                            <tr>
                                <td><em>::selection</em></td>
                                <td className="dark-gold">::selection</td>
                                <td>Selects the portion of an element that is selected by a user</td>
                            </tr>
                        </tbody>
                    </table>
                </article>
            </section>
            <div className="source-space"></div>

            <Footer name="dream-board" />
        </div>
    );
}