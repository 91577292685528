
// import "../generateContent";
import TopNav from "./TopNav";
import Footer from "./Footer";
function About() {
    document.title = "About Aqyanoos";

    return (
        <>
            {/* <!-- nav bar --> */}
            <TopNav cls="About_page" />

            {/* <header className="hidden feature-hdr">
                <div>Aqyanoos creates useful applications for everyone.</div>
            </header> */}

            {/* <!-- main part of the web page --> */}
            <div id="about">

                <section className="about-txt">

                    <div className="flex space-around flex-wrap w-100 mt-50">
                        <div className="des">
                            <h2>
                                Who Are We?
                            </h2>
                            <p>
                                Aqyanoos is a Software company. We <span className="create-web-app bold">Create</span>,
                                <span className="update-web-app bold"> Update</span> and
                                <span className="upgrade-web-app bold"> Upgrade</span> Web and Mobile Applications.
                            </p>

                            <p>
                                Since 2020 we are actively creating free, useful and helpful Apps.  <a href="/best-free-android-apps.html">Read more about our Free Apps</a>.
                                We have also created awesome online tools. You can find them on the <a href="/">Home page</a>. Enjoy using our Apps and Tools.
                            </p>

                            <p>
                                On our YouTube channel &#40;<a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">Coding Aqyanoos</a>&#41;
                                we share and publish courses and tutorials about Coding, Software and Web Development, Computers, Smartphones, Coding Hacks
                                and Tricks...
                            </p>
                        </div>
                        <div className="pic">
                            <img src="/images/web-mobile-app-development-02.png" className="w-100 abt-img" alt="web-mobile-app-development-02" />
                        </div>
                    </div>

                    <div className="my-50 flex space-around flex-wrap rev-sm">
                        <div className="pic abc">
                            <img src="/images/aqyanoos-founder.JPG" className="w-100 aq-f" alt="who is the founder of aqyanoos.com" />
                            {/* <div className="img-txt-over">
                                <div>
                                    We do it for you or  we teach <br /> you  how to do it yourself.
                                </div>
                            </div> */}
                        </div>
                        <div className="des">
                            <h2>The Founder</h2>
                            <p>
                                I'm Sayed (Khalil) Kazimi a System and Software Engineer. Since 2005 I have been working with computers and writing
                                computer programs. I can make any types of Applications(Web, Mobile and Desktop) using the most recent technologies.
                                I enjoy sharing my knowledge and teaching computers and coding. 
                                I teach in my own unique way and many people found it very helpful and easy to understand.
                            </p>

                        </div>
                    </div>
                    <div className="moving-txt-parent">
                        <div className="moving-txt ff-1">With the Most Recent Technology</div>
                    </div>
                    
                    <section>
                        <h3>Our Core Business</h3>

                        <p className="quote">
                            We do it for you or we teach you how to do it Yourself!
                        </p>
                        <ul>
                            <li>
                                Creating New Web Applications
                            </li>
                            <li>
                                Updating Your Web Applications
                            </li>
                            <li>
                                Upgrading Your Web Applications
                            </li>
                            <li>
                                Teaching your IT department the Recent Technologies
                            </li>
                            <li>
                                Teaching you to Find Your Way Around Your Smartphone
                            </li>
                            <li>
                                Teaching Web Development(Front-end and or Back-end)
                            </li>
                            <li>
                                We Setup and Configure Your Computers / Servers
                            </li>
                            <li>
                                Installing Microsoft Office Pack on Your Computer
                            </li>
                            <li>
                                Teaching the online version of Microsoft Office
                            </li>
                            <li>
                                Teaching Google Cloud Technology such as Google Drive
                            </li>
                            <li>
                                Teaching Microsoft Office Pack
                            </li>
                            <li>
                                Teaching you to Find Your Way Around Your Computer
                            </li>
                            <li>
                                Teaching Microsoft Cloud Technology (OneDrive, Office365)
                            </li>
                            <li>
                                Sharing our knowledge on
                                <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener" title="Visit Our YouTube Channel"> YouTube</a> For Free
                            </li>
                            <li>
                                Creating On-demand courses
                            </li>
                            <li>
                                Creating One to One private courses(individual / personal training)
                            </li>
                        </ul>

                        <p className="bold italic angle-bra">
                            If you would like, you can read more about each service on the <a href="/#/services">Services</a> page.
                        </p>
                    </section>

                    {/* <section>
                        <h2>Our Next Goal</h2>
                        <div>
                            We will build a very unique OS, an Operating System that has:
                        </div>

                        <ul>
                            <li>
                                The Highest Performance
                            </li>
                            <li>
                                The Lowest requirements
                            </li>
                            <li>
                                The Lowest storage and memory occupation
                            </li>

                            <li>
                                Fully Customizable UI
                            </li>
                        </ul>
                        <div>
                            Because today's Operating Systems are very very Slow, not Efficient and have very low Performance in compare to the today's computer technolog
                            and the underlying Hardware. They also occupy too much space in the storage.
                        </div>
                    </section> */}
                </section>
            </div>
            <Footer name="dream-board" />
        </>
    );
}

export default About;