
function NotFound() {


    return (
        <section className="not-found txt-c txt-center">
            <section>
                <header className="main-bc main-fc py-5">
                    <h1 className="py-5 fs-5">Not Found</h1>
                </header>

                <section>
                    <p>
                        We have changed some the links. Please go back to the home page and navigate to your favorite page using the menu.
                    </p>
                    <p>Go back to the <a href="/">home page</a></p>
                </section>
            </section>
        </section>
    );
}

export default NotFound;