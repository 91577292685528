
import { useState, useEffect } from "react";
import "./Admin.css";
import { setURLGoogleSheet } from "../Libs";

function AdminPage() {
    const contentTxt = `
        <div class="btn-holder">
        <button class="aqyanoos-messages green">Aqyanoos</button>
        <button class="aq-jobs">Jobs</button>
        <button class="portfolio-messages">Portfolio</button>
        </div>
        <section class="table-holder">    
            <table>
                <thead>
                    <tr>
                        <th>Subject</th>
                        <th>Message</th>
                        <th class="email">Email</th>
                        <th>Date&Time</th>
                    </tr>
                </thead>
                <tbody class="tbody"></tbody>
            </table>
        </section>
            `;

    const [email_, SetEmail_] = useState("");
    const [pass_, SetPass_] = useState("");

    const validateAdmin = (e) => {
        const content_ = document.querySelector(".login-admin .content"), form_ = document.querySelector(".e5w6s8er5sd");

        //skazimi   Bca321+@
        const eCach = window.localStorage.getItem("ex123");
        const pCach = window.localStorage.getItem("px123");
        const email_1 = "skazimi", pass_1 = "Bca321+@";
        let isOk = false;
        if (eCach && eCach === email_1 && pCach && pCach === pass_1) {
            content_.innerHTML = contentTxt;
            form_.classList.add("hidden");
            isOk = true;
        } else {
            if (email_ === email_1 && pass_ === pass_1) {
                form_.classList.add("hidden");
                window.localStorage.setItem("ex123", email_);
                window.localStorage.setItem("px123", pass_);
                content_.innerHTML = contentTxt;
                isOk = true;
            } else {
                const errorBox_ = document.querySelector(".login-admin .sfwe75s4dffd");
                if (errorBox_) {
                    errorBox_.innerHTML = "Something went wrong, please try again!";
                }
            }
        }

        if (isOk) {
            setTimeout(() => {
                const aq = document.querySelector(".aqyanoos-messages");
                const portf = document.querySelector(".portfolio-messages");
                const jobs_ = document.querySelector(".aq-jobs");
                if (aq && portf && jobs_) {
                    aq.addEventListener("click", function (e) {
                        setURLGoogleSheet(1, e.target);
                    });
                    
                    jobs_.addEventListener("click", function (e) {
                        setURLGoogleSheet(3, e.target);
                    });

                    portf.addEventListener("click", function (e) {
                        setURLGoogleSheet(2, e.target);
                        //  e.target.style = "background: green; color: white;"
                    });
                }

                setURLGoogleSheet(1);
            }, 500);
        }
    };

    useEffect(() => {
        validateAdmin();
    }, []);

    return (
        <section className="login-admin">
            <a href="/">Home</a>
            <div className="e5w6s8er5sd">
                <p className="sfwe75s4dffd"></p>
                <input type="text" className="e1m2ail3" placeholder="Email" onChange={(e) => { SetEmail_(e.target.value); }} />
                <br />
                <input type="password" className="p1a2s3s4w5o6r7d8" onChange={(e) => { SetPass_(e.target.value); }} />
                <br />
                <button onClick={validateAdmin}>Submit</button>
            </div>

            <section className="content"></section>
        </section>
    );
}

export default AdminPage;