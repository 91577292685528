
import Footer from "./Footer";
import TopNav from "./TopNav";
export default function PrivacyMain(props) {

    document.title = "Privacy Policy";

    return (
        <>
            <TopNav cls="Privacy_page" />
            <article id="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>This is the Privacy Policy of Aqyanoos softwares and services.</p>
                <p>Please read the Privacy Policy carefully before using, installing or accepting our applications / softwares or services.</p>
                <h2 className="lh-40">End-User License Agreement (EULA) of Aqyanoos Softwares</h2>

                <p>This End-User License Agreement ("EULA") is a legal agreement between you and Aqyanoos.</p>

                <p>This EULA agreement governs your acquisition and use of our
                    softwares
                    ("Software") or Services directly from Aqyanoos or indirectly through an Aqyanoos authorized reseller or distributor (a "Reseller"). </p>

                <p>Please read this EULA agreement carefully before completing the installation process and using our software(s) or Service(s).
                    It provides a license to use our software and contains warranty information and liability
                    disclaimers.</p>

                <p>If you register for a free trial of any of our softwares or services, this EULA
                    agreement
                    will also govern that trial. By clicking "accept" or installing and/or using any of our applications or services,
                    you are confirming your acceptance of the Software or the service and
                    agreeing to become bound
                    by
                    the terms of this EULA agreement.</p>

                <p>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that
                    you
                    have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have
                    such
                    authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use
                    the
                    Software or the service, and you must not accept this EULA agreement.</p>

                <p>This EULA agreement shall apply only to the Software or service supplied by Aqyanoos
                    herewith regardless of whether other software or service is referred to or described herein. The terms also apply to
                    any Aqyanoos updates, supplements, Internet-based services, and support
                    services
                    for the Software, unless other terms accompany those items on delivery. If so, those terms apply.
                </p>

                <h3>License Grant</h3>

                <p>Aqyanoos hereby grants you a personal, non-transferable, non-exclusive
                    licence
                    to use our softwares on your devices in accordance with the
                    terms
                    of
                    this EULA agreement.</p>

                <p>You are permitted to load our softwares (for example a PC,
                    laptop,
                    mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum
                    requirements of our softwares/applications.</p>

                <p>You are not permitted to:</p>

                <ul>
                    <li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor
                        permit
                        the whole or any part of the Software to be combined with or become incorporated in any other software,
                        nor
                        decompile, disassemble or reverse engineer the Software or attempt to do any such things</li>
                    <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
                    <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
                    <li>Use the Software in any way which breaches any applicable local, national or international law</li>
                    <li>use the Software for any purpose that Aqyanoos considers is a breach
                        of
                        this EULA agreement</li>
                </ul>

                <h3>Intellectual Property and Ownership</h3>

                <p>Aqyanoos shall at all times retain ownership of the Software as originally
                    downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and
                    other intellectual property rights of whatever nature in the Software, including any modifications made
                    thereto)
                    are and shall remain the property of Aqyanoos.</p>

                <p>Aqyanoos reserves the right to grant licences to use the Software to third
                    parties.</p>

                <h3>Termination</h3>

                <p>This EULA agreement is effective from the date you first use the Software and shall continue until
                    terminated.
                    You may terminate it at any time upon written notice to Aqyanoos.</p>

                <p>It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such
                    termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop
                    all
                    access and use of the Software. The provisions that by their nature continue and survive will survive any
                    termination of this EULA agreement.</p>

                <h3>Governing Law</h3>

                <p>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be
                    governed
                    by and construed in accordance with the laws of Belgium.</p>

                <section className="data-safety">
                    <h2>Data Safety</h2>
                    <h3>User Data</h3>
                    <p>
                        Our softwares do not collect any information about a
                        user,
                        and does not collect any
                        information about the device. Our softwares do not
                        collect
                        user data, does not use user data
                        and does not share user data. In fact, all softwares made by Aqyanoos
                        do
                        not do anything with user data.
                        Our softwares do not collect, do not share and do not use any information about users and devices.
                    </p>

                    <h3>Personal and Sensitive User Data</h3>
                    <p>
                        To use Our softwares, you do not need to be authenticated,
                        we
                        do not sell anything
                        so you will not be asked to provide us payment information. Our softwares do not collect
                        authentication information, does not collect financial and payment information, has no access to the
                        phonebook, has no access to the
                        SMS, has no access to call related data, has no access to health data, health connect data, inventory of
                        other apps on the device, microphone, camera, and other sensitive device or usage data.
                        We do not collect, use or share information related to users and devices.
                        All Softwares or Applications created by Aqyanoos do not allow sign-in, do not allow sign-up
                        and do not allow creating any accounts.
                    </p>

                    <h3>Contact Details</h3>
                    <p>
                        If you contacted us using the contact form, then we keep your Name, Email and other information provided
                        by you in our database for the purpose of providing you the best service we can and getting back to you
                        whenever it is necessary. You can request Deletion of Personal Information by sending us a request with the title
                        <strong>"Deletion of Personal Information"</strong> and we remove all your personal Data within <em>48 hours</em>.
                    </p>

                    <section className={`google-ads`}>
                        <h3>Google Ads</h3>
                        <p>
                            Some of Our softwares use Google Mobile Ads SDK.
                            The SDK collects and shares some data automatically.
                            It collects information related to the performance of the app and the SDK, including crash logs, app
                            launch time, hang rate, and energy usage, it collects Android advertising (ad) ID, app set ID, and,
                            if applicable, other identifiers related to signed-in accounts on the device...
                            <a href="https://developers.google.com/admob/android/privacy/play-data-disclosure" target="_blank" rel="noreferrer noopener">
                                read more on Google Ads Policy page</a>....All these data and information
                            are collected to provide good accurate services, following the rules and laws of countries in different locations
                            and not for any other purposes. <br />
                            Aqyanoos as the developer and as the owner of
                            the software has no control over the collection of
                            data by the Google Mobile Ads SDK.
                        </p>
                    </section>

                    <p>
                        <strong>
                            We hope that now everything is clear. We are doing more than our best to provide you the Best services,
                            tools, softwares and applications. We hope you are satisfied with our services and softwares.
                        </strong>
                    </p>

                    <p>
                        <strong>
                            Thank you for being here.
                        </strong>
                    </p>


                </section>

            </article>
            <Footer />
        </>
    );
}
