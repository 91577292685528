import Footer from "./Footer";
import "./Home.css";

import { Link } from "react-router-dom";

import MenuIcon from "./MenuIcon";

function Home() {
    // useEffect(() => {
    //     const iframe = document.getElementById("ifr-aq-123")

    //     if (iframe) {
    //         const inputEmail = iframe.contentWindow.document.getElementsByTagName("input")[0];
    //         if (inputEmail) {
    //             inputEmail.style.border = "1px solid #000"
    //         }
    //     }
    // }, [])
    const TE = "/images/textEditor_icon.png";
    const HRC = "/images/hrc_icon.png";
    const CR = "/images/cr_icon.png";
    const DB = "/images/db_icon.png";
    const FCD = "/images/fcd_icon.png";
    /*
        function toYr(d) {
            const days = Math.round((Date.now() - new Date(d)) / 1000 / 60 / 60 / 24);
            if (days > 364) {
                let yr = Math.floor(days / 365);
                yr = yr + " year" + (yr > 1 ? "s" : "");
                let days_ = days % 365;
                days_ = days_ > 0 ? " and " + days_ + " day" + (days_ > 1 ? "s" : "") : "";
                return yr + days_ + " ago";
            } else {
                return days > 1 ? days + " days ago" : " Today";
            }
        };
    
        const difFRhrc = toYr("2022-02-09");
        const difFRtextEditor = toYr("2022-02-23");
        const difFRCR = toYr("2023-01-9");
        const difFRDB = toYr("2023-02-22");
        const difFRfcd = toYr("2023-10-09");
    */
    return (
        <>
            <article className="home">
                {/* <!-- side notes --> */}
                {/* <!-- showcase --> */}
                <div className="showcase flex f-col space-around white txt-select-none">
                    <div className="flex aln-c first">
                        <div id="logo_txt">
                            {/* <!-- <img src="./images/app_icons/android-chrome-512x512.png" alt="logo"> --> */}
                            {/* <div className="txt-c flex space-between">
                                <span className="black">A</span>
                                <span className="red">q</span>
                                <span className="green">y</span>
                                <span className="gold">a</span>
                            </div>
                            <div className="blue txt-c">NOOS</div> */}
                            <img src="/images/android-chrome-512x512.png" alt="company log" />
                        </div>
                        <div className=" show-case-txt">
                            {/* <div className="fs-10 p0 ms-15">A</div> */}
                            <div className="fs-2 px-15">Aqyanoos Creates useful and helpful Apps, Tools and Software for everyone!</div>
                        </div>
                    </div>
                </div>

                {/* <!-- navigation --> */}
                <div id="main-nav">
                    <div className={`menu-icon-home`} >
                        <MenuIcon class="menu-icon" />
                        <div className="home-second-nav">
                            <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">YouTube</a> <span>|</span>

                            <a href="/online-text-or-code-editor-notepad.html" title="Online Notepad">Online Notepad</a> <span>|</span>

                            <a href="/services.html" rel="noreferrer noopener nofollow">Services</a>
                        </div>
                    </div>

                    <div className={`header-menu mt-20`}>
                        <div className="flex cont-center aln-c child-ms-5">
                            <MenuIcon class="menu-icon-lg h" />
                            <a href="/" className="current ms-35">Home</a>
                            <Link to="/services" rel="noreferrer noopener nofollow">Services</Link>
                            <Link to="/tutorials-source-code" rel="noreferrer noopener nofollow">Source Code</Link>
                            <Link to="/contact" rel="noreferrer noopener nofollow">Contact</Link>

                        </div>

                        <hr />
                        <div className="nav">
                            <nav className="home-second-nav">
                                <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">YouTube</a> <span>|</span>
                                {/* <Link to="/online-notepad">Online Notepad</Link> <span>|</span> */}
                                <a href="/online-text-or-code-editor-notepad.html">Online Notepad</a> <span>|</span>
                                <a href="/free-online-qr-code-generator-for-payment.html" rel="noreferrer noopener ">QR-Code Generator</a>
                            </nav>
                        </div>
                    </div>
                </div>
                <hr />
                <section className="content-section">
                    <header>
                        <h1 className="title">Software, App and Web Development Services</h1>
                    </header>
                    <p>
                        We create custom software, mobile and web Apps. We listen to your story and your ideas and turn them into a real, useful application.
                        We build maintainable and extendable software and websites using the most recent technologies.
                    </p>

                    <p>
                        We can update and upgrade your existing Apps to the latest technology to make them secure and perform efficiently.
                        Check out our  <a href="/services.html" rel="noreferrer noopener nofollow">Services page</a>.
                    </p>

                    <h2 className="title">Software Engineering and Web Development Courses</h2>

                    <p>
                        We teach you to become a successful Software Engineer, a great problem solver that companies love to hire.
                        We teach you programming, UI design, Algorithms, Database, APIs and everything you need to create
                        professional Apps and Websites (in a unique method step by step).
                        Watch and follow our  <a href="https://www.youtube.com/playlist?list=PLdYDtj_DxqzCCbpvGDwUH-aE8dVeA2Bf5" target="_blank" rel="noreferrer noopener nofollow">programming courses</a>.

                    </p>
                </section>


                <hr />
                <section className=" online-tools">
                    <h2>Our Free Online Tools</h2>
                    <p>We have created the following tools on this website and we are creating more useful tools that
                        will come online soon. Check our website regularly.
                    </p>
                    <div className="group-online-tools">

                        <div className="pro-tools ">
                            <div className="flex space-around mt-15">
                                <img src="/images/qr-code-generator-bank-payment.png" alt="qr-code-generator for making bank-payment request" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Fully customizable Online QR Code Generator for Bank Payment ...<a href="/free-online-qr-code-generator-for-payment.html" >more</a>
                            </p>
                        </div>

                        <div className="pro-tools ">
                            <div className="flex space-around mt-15">
                                <img src="/images/free-online-notepad-text-code-editor.png" alt="online-notepad-text-code-editor for free" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Online Notepad or Text Editor - With Fully Customizable User Interface and with Auto Back-up ...<a href="/online-text-or-code-editor-notepad.html" >more</a>
                            </p>
                        </div>

                        <div className="pro-tools ">
                            <div className="flex space-around mt-15">
                                <img src="/images/qr-code-generator-text-link-url.png" alt="qr-code-generator for or from any text , link , url" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Online QR Code Generator from any Text, Link or URL with Your Custom Logo and Favorite Colors ...<a href="/free-online-qr-code-generator.html" >more</a>
                            </p>
                        </div>

                        <div className="pro-tools ">
                            <div className="flex space-around mt-15">
                                <img src="/images/online-hexadecimal-to-decimal-or-integer-convertor-.png" alt="convert hex or hexadecimal to decimal or integer online" />
                            </div>
                            <p className="m0 p10 txt-c">
                            Convert Hex or Hexadecimal to Decimal / Integer or vice versa Online ...<a href="/#/how-to-convert-decimal-to-hex" rel="noreferrer noopener nofollow">more</a>
                            </p>
                            
                        </div>
                    </div>
                </section>
                <hr />
                <section>
                    <h2 className="txt-c">Our Android Applications</h2>
                    <p className="px-15 txt-c">We created the following Android applications for you. Install and enjoy using them for free!</p>
                    <article className="group-projects">
                        <div className="android-app ">
                            <p className="txt-c txt-b py-10 m0">Dream Board(Drawing App)</p>
                            <div className="flex space-around">
                                <div className="android-app-logo db"></div>
                                <div className="w-40 flex f-col cont-center"><a
                                    href="https://play.google.com/store/apps/details?id=com.aqyanoos.dreamboard" target="_blank"
                                    title="Visit the app on Google play" rel="noreferrer noopener nofollow">Visit on Google Play</a></div>
                            </div>
                            <p className="m0 p10 txt-c">Fully Customizable Feature Rich Drawing and Painting App. Draw anything and save it on your phone / device.</p>
                        </div>
                        <div className="android-app ">
                            <p className="txt-c txt-b py-10 m0">Color Tools and Reference</p>
                            <div className="flex space-around">
                                <div className="android-app-logo cr"></div>
                                <div className="w-40 flex f-col cont-center"><a
                                    href="https://play.google.com/store/apps/details?id=com.aqyanoos.colorreference" target="_blank"
                                    title="Visit the app on Google play" rel="noreferrer noopener nofollow noopener">Visit on Google Play</a></div>
                            </div>
                            <p className="m0 p10 txt-c">Convert, pick, preview, copy and share colors. A perfect App if you
                                deal with colors everyday. RGB, HEX, HSL, Color Names in EN...</p>
                        </div>
                        <div className="android-app ">
                            <p className="txt-c txt-b py-10 m0">Text Or Code Editor</p>
                            <div className="flex space-around">
                                <div className="android-app-logo te"></div>
                                <div className="w-40 flex f-col cont-center"><a
                                    href="https://play.google.com/store/apps/details?id=com.aqyanoos.texteditor" target="_blank"
                                    title="Visit the app on Google play" rel="noreferrer noopener nofollow">Visit on Google Play</a></div>
                            </div>
                            <p className="m0 p10 txt-c">Feature rich Notepad or Text Editor. Install and Enjoy Creating, Reading, Editing files, Taking Notes,...</p>
                        </div>
                        <div className="android-app ">
                            <p className="txt-c txt-b py-10 m0">HTTP Response Code</p>
                            <div className="flex space-around">
                                <div className="android-app-logo hrc"></div>
                                <div className="w-40 flex f-col cont-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.aqyanoos.httpresponsecode" target="_blank"
                                        title="Visit the app on Google play" rel="noreferrer noopener nofollow">Visit on Google Play</a>
                                </div>
                            </div>
                            <p className="m0 p10 txt-c">Search, Practice and Learn HTTP Response Status Codes. A
                                perfect Reference App for Developers!</p>
                        </div>

                        {/* <div className="android-app ">
                            <p className="txt-c txt-b py-10 m0">Fake Camera Detector</p>
                            <div className="flex space-around">
                                <div className="android-app-logo fcd"></div>
                                <div className="w-40 flex f-col cont-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.aqyanoos.fakecameradetector" target="_blank"
                                        title="Visit the app on Google play" rel="noreferrer noopener nofollow">Visit on Google Play</a>
                                </div>
                            </div>
                            <p className="m0 p10 txt-c">This android app can detect how many camera(s) is/are on your Android Device.</p>
                        </div> */}
                    </article>
                </section>

                {/* <!-- short intro --> */}

                <section className="home-text">
                    <h2 className="txt-c title">Courses and Trainings for Beginners and Intermediates</h2>
                    <article className="group-courses">

                        <div className="pro-course ">
                            <p className="txt-c txt-b py-10 m0">Learn Smartphones</p>
                            <div className="flex space-around">
                                <img src="/images/smartphone-tutorial-for-beginners.png" alt="smartphone courses and tutorials" />
                            </div>
                            <p className="m0 p10 txt-c">
                                In this course you learn how to manage everything about your devices yourself...<a href="/services.html#learn-smartphones" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-course ">
                            <p className="txt-c txt-b py-10 m0">Learn Google Drive</p>
                            <div className="flex space-around">
                                <img src="/images/google-drive-full-course-tutorial.png" alt="Learn Google Drive" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Google Drive Complete Course. Learn Cloud Services, Office Apps...<a href="/services.html#google-drive-course-online-free" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-course ">
                            <p className="txt-c txt-b py-10 m0">Learn Microsoft Office Pack</p>
                            <div className="flex space-around">
                                <img src="/images/microsoft-office-pack-offline.png" alt="Learn Microsoft Office Pack" />
                            </div>
                            <p className="m0 p10 txt-c">
                                A Complete Course About Microsoft Office Pack...<a href="/services.html#microsoft-office-pack-tutorial-for-free" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-course ">
                            <p className="txt-c txt-b py-10 m0">Learn Computers</p>
                            <div className="flex space-around">
                                <img src="/images/learn-computer-basics-003.png" alt="learn computer basics" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Find your way around Computers, how to use and customize them...<a href="/services.html#learn-computer-basics" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-course ">
                            <p className="txt-c txt-b py-10 m0">Learn Microsoft OneDrive</p>
                            <div className="flex space-around">
                                <img src="/images/ms-onedrive-online.png" alt="Learn Microsoft OneDrive" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Learn The Newest IT Technologies, Cloud Services, Office Apps,...<a href="/services.html#microsoft-onedrive-course-for-free" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-course ">
                            <p className="txt-c txt-b py-10 m0">Learn more...</p>
                            <div className="flex space-around">
                                <img src="/images/learn-more-001.jpg" style={{ width: 170 + "px" }} alt="computer courses for free" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Want to know more about our Courses and Trainings? <a href="/services.html#courses-trainings" rel="noreferrer noopener nofollow">...more</a>.
                            </p>
                        </div>
                    </article>

                    <h2 className="txt-c title">Popular Web Development Services</h2>
                    <article className="group-services">

                        <div className="pro-service ">
                            <p className="txt-c txt-b py-10 m0">Creating New Web Apps</p>
                            <div className="flex space-around">
                                <img src="/images/build-your-first-website.png" alt="Creating New Web Apps" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Need a website or management applications? We Can Build it for you...<a href="/services.html#create-new-website" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-service ">
                            <p className="txt-c txt-b py-10 m0">Updating Web Apps</p>
                            <div className="flex space-around">
                                <img src="/images/how-to-update-an-old-website.png" alt="how-to-update-an-old-website" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Updating Web Applications is crucial for security, efficiency and performance...<a href="/services.html#update-your-website" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-service ">
                            <p className="txt-c txt-b py-10 m0">Upgrading Web Apps</p>
                            <div className="flex space-around">
                                <img src="/images/how-to-upgrade-old-websites.png" alt="how-to-upgrade-old-websites" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Your Web Apps are too old? Want to switch technologies? We upgrade it for you...<a href="/services.html#upgrade-your-website" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-service ">
                            <p className="txt-c txt-b py-10 m0">Learning Web Development</p>
                            <div className="flex space-around">
                                <img src="/images/web-development-course-online-free.png" alt="learn web development course" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Learn Web Technologies, Become a Professional Web Developer...<a href="/services.html#teaching-ICT-staff-web-development" rel="noreferrer noopener nofollow">more</a>
                            </p>
                        </div>

                        <div className="pro-service ">
                            <p className="txt-c txt-b py-10 m0">Explore our Services</p>
                            <div className="flex space-around">
                                <img src="/images/learn-more-002.jpg" style={{ width: 170 + "px" }} alt="web-development-courses-for-free" />
                            </div>
                            <p className="m0 p10 txt-c">
                                Want to know all of our web and software development services?<a href="/services.html#web-development-services" rel="noreferrer noopener nofollow">...more</a>.
                            </p>
                        </div>
                    </article>
                </section>

                {/* <!-- Android apps --> */}
                <section>
                    <h2 className="hdr">Our Android Applications</h2>
                    <hr />
                    <section className="table-holder">

                        <table id="my-apps">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {/* <th>Details</th> */}
                                    <th>Description</th>
                                    <th>Logo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-c">HTTP Response Code <br />Or <br />HTTP Status Code</td>
                                    {/* <td className="details">
                                        <span className="txt-b">First Release:</span> Feb 9, 2022 <br />
                                        <span className="txt-b">Published: </span><span id="dif-FRhrc">{difFRhrc}</span> <br />
                                        <span className="txt-b">Version:</span> 2.0.4 <br />
                                         <span className="txt-b">Downloads:</span> 100+ 
                                    </td> */}
                                    <td className="des">
                                        This android app helps you Search, Practice and Learn HTTP Status Codes or HTTP Response Codes.
                                        Check the application on Google Play by clicking <a
                                            href="https://play.google.com/store/apps/details?id=com.aqyanoos.httpresponsecode"
                                            target="_blank" rel="noreferrer noopener nofollow">here</a>.
                                    </td>
                                    <td className="icon txt-c">
                                        <img src={HRC} alt="http status code or http response code" />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="txt-c">Dream Board</td>
                                    {/* <td className="details">
                                        <span className="txt-b">First Release:</span> Feb 22, 2023 <br />
                                        <span className="txt-b">Published: </span><span id="dif-FRDB">{difFRDB}</span> <br />
                                        <span className="txt-b">Version:</span> 2.0.5 <br />
                                         <span className="txt-b">Downloads:</span> 50+ 
                                    </td> */}
                                    <td className="des">
                                        If you like drawing anything on your smartphone then this app is perfect for you. Draw anything with
                                        different pen size, pen color,
                                        background color, pen transparency,...and save your drawing as image on your device. Check the app on
                                        Google Play to learn more about it.
                                        Check the application on Google Play by clicking <a
                                            href="https://play.google.com/store/apps/details?id=com.aqyanoos.dreamboard" target="_blank" rel="noreferrer noopener nofollow">here</a>.
                                    </td>
                                    <td className="icon txt-c">
                                        <img src={DB} alt="Dream Board" />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="txt-c">Color Reference</td>
                                    {/* <td className="details">
                                        <span className="txt-b">First Release:</span> Jan 9, 2023 <br />
                                        <span className="txt-b">Published: </span><span id="dif-FRCR">{difFRCR}</span> <br />
                                        <span className="txt-b">Version:</span> 2.0.4 <br />
                                        <span className="txt-b">Downloads:</span> 100+ 
                                    </td> */}
                                    <td className="des">
                                        This android app is created to help you with colors. This is a perfect application for those who deal with
                                        colors everyday.
                                        Check the application on Google Play by clicking <a
                                            href="https://play.google.com/store/apps/details?id=com.aqyanoos.colorreference"
                                            target="_blank" rel="noopener noreferrer nofollow">here</a>.
                                    </td>
                                    <td className="icon txt-c">
                                        <img src={CR} alt="Color Reference" />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="txt-c">Text Editor <br />Or<br /> Code Editor</td>
                                    {/* <td className="details">
                                        <span className="txt-b">First Release:</span> Feb 23, 2022 <br />
                                        <span className="txt-b">Published: </span><span id="dif-FRtextEditor">{difFRtextEditor}</span> <br />
                                        <span className="txt-b">Version:</span> 2.0.3 <br />
                                        <span className="txt-b">Downloads:</span> 100+ 
                                    </td> */}
                                    <td className="des">
                                        If you like to create, edit, open, read, modify text based files on your phone then this app is
                                        perfect for you.
                                        This android app is able to open or read files, create new files and edit or modify files. This is almost like Windows Notepad.
                                        With this Android application you can create custom files with custom extensions.
                                        This is also a great Code Editor for developers and programmers.
                                        Check the application on Google Play by clicking <a
                                            href="https://play.google.com/store/apps/details?id=com.aqyanoos.texteditor" target="_blank" rel="noreferrer noopener nofollow">here</a>.
                                    </td>
                                    <td className="icon txt-c">
                                        <img src={TE}
                                            alt="Text Editor for Android devices or Code Editor for Android devices" />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="txt-c">Fake Camera Detector</td>
                                    {/* <td className="details">
                                        <span className="txt-b">First Release:</span> Oct 9, 2023 <br />
                                        <span className="txt-b">Published: </span><span id="dif-FRfcd">{difFRfcd}</span> <br />
                                        <span className="txt-b">Version:</span> 1.0.4 <br />
                                         <span className="txt-b">Downloads:</span> 100+
                                    </td> */}
                                    <td className="des">
                                        This Android App can detect how many cameras are there on your smartphone so that you know if any of those cameras on your device
                                        is fake or real. This App detects all physical and logical cameras so don't be shocked if you see a big number.
                                        Check the application on Google Play by clicking <a
                                            href="https://play.google.com/store/apps/details?id=com.aqyanoos.fakecameradetector" target="_blank" rel="noreferrer noopener nofollow">here</a>.
                                    </td>
                                    <td className="icon txt-c">
                                        <img src={FCD}
                                            alt="Fake Camera Detector for Android devices. Find out how many cameras are there on your smartphone" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </section>


                <div className="mt-50"></div>
                {/* <iframe id="ifr-aq-123"  src="https://docs.google.com/forms/d/e/1FAIpQLSe1VBso62RGZTsVXkGQGIj165XZsrebQkpJwtcIeUNySLFT9Q/viewform?embedded=true" width="640" height="418" >Loading…</iframe> */}
            </article>
            <Footer name="dream-board" />
        </>
    );
};

export default Home;