
import { Link } from "react-router-dom";
import MenuIcon from "./MenuIcon";

function TopNav(props) {
    const isServiceP = props.cls === 'Services_page' || document.location.href.includes("services.html")
    return (
        <>
            <section className=" top-menu-rest">
                <MenuIcon class="menu-icon" />
            </section>

            <section className=" main-nav">
                <nav className="main-nav-holder">
                    <MenuIcon class="menu-icon-lg" />
                    <a href="/" className='Home_page'>Home</a>
                    <Link to="/services" className={`Services_page ${isServiceP ? ' current' : ''}`}>Services</Link>
                    <Link to="/tutorials-source-code" className={`Tutorials_page ${props.cls === 'Tutorials_page' ? ' current' : props.cls === 'Privacy_page' ? ' hidden' : ''}`}>Source Codes</Link>
                    <Link to="/tutorials-source-code" className={`Privacy_page ${props.cls === 'Privacy_page' ? ' current' : ' hidden'}`}>Privacy Policy</Link>
                    <Link to="/contact" className={`Contact_page ${props.cls === 'Contact_page' ? ' current' : ''}`}>Contact</Link>
                    <Link to="/about" className={`About_page ${props.cls === 'About_page' ? ' current' : ''}`}>About</Link>
                </nav>
            </section>
        </>
    );
}

export default TopNav;