import TopNav from "./TopNav";
// import { setPageData } from "../Libs";
import Footer from "./Footer";
import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

function Tutorials(props) {

    // setPageData(
    //     'Useful Tutorials',
    //     "Learn to code and become the best programmer?",
    //     "Visit our website if you would like to become a perfect programmer and find your dream job in IT within a few months",
    //     "html, css, javascript, js, java, python, c#, c++, learn coding, learn programming, learn javascript, learn python, learn html, learn css, learn c#, learn c++"
    // );

    const [name, setName] = useState('');

    useEffect(() => {
        let n_ = window.location.href.split("?");
        if (n_) {
            n_ = n_[1];
            if (n_) {
                n_ = n_.split("=")[1];
                setName(n_);
            }
        }
    }, []);

    return (
        <>
            <TopNav cls="" />

            <header className="page-header">
                <h3>Useful Tutorials</h3>
            </header>
            <section id="main" className="source-codes">
                <article>
                    <nav id="tutorial-list">

                        <a href="/list-of-all-css-selectors.html" className={`tl-item  ${name === '1' ? " b-current" : ''}`}>List Of All CSS Selectors</a><br /> <br />
                        <a href="/how-to-integrate-mongodb-in-asp-net-core-web-api.html" className={`tl-item  ${name === '2' ? " b-current" : ''}`}>How to integrate MongoDB in ASP .Net Core Web API?</a><br /> <br />
                        <a href="/how-to-make-qr-code-generator-html-css-js.html" className={`tl-item  ${name === '3' ? " b-current" : ''}`}>How to Make QR Code Generator using HTML CSS and JS (JavaScript)?</a><br /> <br />
                        <a href="/host-react-app-github.html" className={`tl-item  ${name === '4' ? " b-current" : ''}`}>How to Host React.js App on GitHub?</a> <br /> <br />
                    </nav>
                    {/* original */}
                    <section>
                        <h2>Useful information</h2>
                        <p>
                            Select a tutorial from the list above and you will see the source code.
                            I hope you find my Tutorials helpful.
                        </p>
                        <p>
                            For each course or tutorial there is also a video course on my YouTube Channel &#40;<a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">Coding Aqyanoos</a>&#41;.
                        </p>
                        <p>
                            <em>Use your knowledge for good!</em>
                        </p>
                    </section>
                </article>
            </section>
            <div className="source-space"></div>

            <Footer name="dream-board" />
        </>
    );
}

export default Tutorials;