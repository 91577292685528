export function aqyanoosCustomAlert(title, description) {
    if (document.querySelector('.custom-alert')) {
        document.querySelector('.custom-alert').remove();
    }

    const secEl = document.createElement('section');
    secEl.className = "custom-alert";
    secEl.innerHTML = `
    <section class="ca-container">
            <div class="ca-title">${title}</div>
            <hr>
            <div class="ca-description">${description}</div>
            
            <div class="ca-ok" onclick="document.querySelector('.custom-alert').remove()">OK</div>
        </section>
    `;

    document.body.appendChild(secEl);
}

export function copyToClipboardWeb(txt) {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            navigator.clipboard.writeText(txt).then(() => {
                aqyanoosCustomAlert("Copy Code", "You have successfully copied the code to the clipboard.");
            }, () => {
                aqyanoosCustomAlert("Copy Code", "Copying code to the clipboard failed, please try again!");
            });
        }
    });
}

const copyBtnsMongoWebApi = document.querySelectorAll(".copy-btn.mongodb-webapi");
if (copyBtnsMongoWebApi) {
    copyBtnsMongoWebApi.forEach(btn => {
        btn.onclick = function (e) {
            const parentElChild = btn.parentElement.children;
            for (const el of parentElChild) {
                console.log(el);
                if (el.className.includes("code-holder")) {
                    copyToClipboardWeb(el.value);
                }
            }
        };
    });
}

export function setPageData(title, mTitle, mDes, mKeys) {
    document.title = title;
    const mT = document.querySelector("meta[name='title']");
    const mD = document.querySelector("meta[name='description']");
    const mK = document.querySelector("meta[name='keywords']");

    mT.content = mTitle;
    mD.content = mDes;
    mK.content = mKeys;
}

// side menu
export function toggleMenu() {
    const menuWrapper = document.querySelector(".menu-wrapper");
    if (menuWrapper)
        menuWrapper.classList.toggle("open");
}

const sideMenuIcon = document.querySelector(".menu-icon"), sideMenuIconLg = document.querySelector(".menu-icon-lg");
const sideMenuActionBtn = document.querySelectorAll(".side-menu-action-btn");

if (sideMenuIcon && sideMenuActionBtn) {
    sideMenuIcon.onclick = toggleMenu;
    sideMenuIconLg.onclick = toggleMenu;

    sideMenuActionBtn.forEach(item => {
        item.onclick = toggleMenu;
    });
}

// end side menu

export function isSmallDevice() {
    return window.innerWidth < 760;
}

export function selectAll(el) {
    if (el) {
        el.select();
    }
}


export function playServiceImgCss(e, mode, listID) {
    // get all images
    const images = document.querySelectorAll("." + listID + " .demo-img");
    const totalImages = images.length;

    const targetCounterActive = document.querySelector("." + listID + " .service-img-counter .active");
    targetCounterActive.classList.remove("active");

    let activeID = Number(targetCounterActive.className.split("_")[1]);

    const currentImage = document.querySelector("." + listID + " .demo-img.img-" + activeID);
    currentImage.classList.add("hidden");

    // if it is the first or the last, grayedout the btn
    if (mode === "F" && activeID < totalImages - 1) {
        activeID++;
    }

    if (mode === "B" && activeID > 0) {
        activeID--;
    }

    if (activeID === totalImages - 1) {
        // no next
        const nextBtn = document.querySelector("." + listID + " .service-img-ctrl-f");
        nextBtn.classList.add("disabled");

    } else if (activeID === 0) {
        // no back
        const backBtn = document.querySelector("." + listID + " .service-img-ctrl-b");
        backBtn.classList.add("disabled");
    } else {
        const disabledBtn = document.querySelector("." + listID + " .service-img-ctrl .disabled");
        if (disabledBtn) {
            disabledBtn.classList.remove("disabled");
        }
    }

    const newActive = document.querySelector("." + listID + " .service-img-counter .xxx_" + activeID);
    newActive.classList.add("active");

    const newImage = document.querySelector("." + listID + " .demo-img.img-" + activeID);
    newImage.classList.remove("hidden");
}

export function setURLGoogleSheet(mode, target_) {
    const tbody_ = document.querySelector(".login-admin .table-holder .tbody");
    // default: aqyanoos contact
    let url_ = "https://docs.google.com/spreadsheets/d/1VWOX6t9QvdqEM7oxl2URwngCxFAs-PGNKuHrZPiczLs/gviz/tq?sheet=Contact_Page";

    tbody_.innerHTML = "";
    const activeBtn = document.querySelector(".login-admin .content .btn-holder .green")
    if (target_ && activeBtn) {
        activeBtn.classList.remove("green");
        target_.classList.add("green");
    }

    // portfolio
    if (mode === 2) {
        url_ = "https://docs.google.com/spreadsheets/d/1BOjv0aPWkfibeXFPWYbwbFUlPp_IQtDkRTxWy4Qgkn0/gviz/tq?sheet=Form_Responses_1";
    }

    if (mode === 3) {
        url_ = "https://docs.google.com/spreadsheets/d/1VWOX6t9QvdqEM7oxl2URwngCxFAs-PGNKuHrZPiczLs/gviz/tq?sheet=Application_Form";
    }

    fetch(url_).then(rr => rr.text())
        .then(text_ => text_.split("setResponse(")[1])
        .then(data => {
            // console.log(data)
            const getJsonPart = data.slice(0, data.length - 2);
            const toJs = JSON.parse(getJsonPart);
            const rows_ = toJs.table.rows;
            rows_.forEach(row => {
                const tr_ = document.createElement("tr");
                if(mode === 3) {

                    let motLin = "\n\n"
                     motLin += row.c[3] ? row.c[3]["v"] : ""
                    motLin += "\n\n" + (row.c[4] ? row.c[4]["v"] : "")
                    motLin += "\n";
                    
                    tr_.innerHTML = `
                        <td class="subject"> ${row.c[1] ? row.c[1]["v"] : ""}</td>
                            <td class="msg">
                                <textarea readonly>${motLin}</textarea>
                                </td>
                            <td class="email">${row.c[2] ? row.c[2]["v"] : ""}</td>
                            <td>${row.c[0]["f"].split(" ")[0]}</td>`;
                } else {
                    tr_.innerHTML = `
                        <td class="subject"> ${row.c[2] ? row.c[2]["v"] : ""}</td>
                            <td class="msg">
                                <textarea readonly>${row.c[3] ? row.c[3]["v"] : ""}</textarea>
                                </td>
                            <td class="email">${row.c[1] ? row.c[1]["v"] : ""}</td>
                            <td>${row.c[0]["f"].split(" ")[0]}</td>`;
                }

                tbody_.appendChild(tr_);
            });
            // console.log(toJs)
        }).catch(e => console.log("Error google sheets: ", e));
}

export function jumpToSec(id) {
    let section_, sectionId = id;
    const url_ = window.location.href;

    if (id) {
        // on click
        section_ = document.getElementById(id);
        // set the path
        let firstPart = url_.split("services")[0];
        window.location.href = firstPart + "services#" + id;

    } else {
        // on page load
        sectionId = url_.split("services")[1];
        if (sectionId) {
            section_ = document.querySelector(sectionId);
        }
    }

    if (section_) {
        let position = "center";

        if (sectionId.includes("update-your-website")) {
            position = "end";
        }
        section_.scrollIntoView({ block: position, behavior: "smooth" });
        //section_.scrollTo({ top: 1550, behavior: "smooth" }) position ? position :
    }

    console.log("jumping to section", section_);
}

export function localCurrency() {
    return navigator.language.includes("en") ? "$" : "€";
}

export class ServicePrice {
    constructor(old, discount) {
        this.old = old;
        this.discount = discount;
    }

    newPrice() {
        let newDigit = (this.old * ((100 - this.discount) / 100)).toFixed(2);
        const sdf = newDigit.toString().split(".");
        if (sdf[1] === "00") {
            newDigit = Number(sdf[0]);
        }
        return newDigit;
    }
}