
import "./HexDecimal.css";
import { aqyanoosCustomAlert, setPageData } from "../Libs";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function HexDecimal(props) {

    const [xyz, setXyz] = useState(0);
    const updateState_ = () => {
        setXyz(Math.random() * 100 * Math.random() * 5458);
    };

    setPageData(
        `Convert   ${props.name === "decimal" ? "Convert decimal or integer to hex" : "Convert Hex to decimal"}`,
        `How to convert ${props.name === "decimal" ? "Decimal or integer to hex or hexadecimal" : "Hex to Decimal"}  using javascript?`,
        `Convert  ${props.name === "decimal" ? "Decimal to Hex" : "Hex to Decimal"} with javascript and html page`,
        "hex, decimal, integer, hexadecimal, convert, converter, html, js, javascript"
    );


    const numberConvertor = (v_) => {
        const resultEl = document.getElementById("result");
        let res_ = 0;
        if (props.name === "decimal") {
            if (v_) {
                res_ = Number(v_);
                if (!res_) {
                    aqyanoosCustomAlert("Warning", "Please enter values between 0 - 9");
                    return;
                }

                res_ = res_.toString(16);
            }
        } else {
            if (v_) {
                let base = "0x" + v_;
                res_ = Number(base);
                if (!res_) {
                    // aqyanoosCustomAlert("Warning", "Please enter values between 0 - 9 and A-F");
                    aqyanoosCustomAlert("Warning", "Please enter values between 0 - 9 and A-F");
                    return;
                }
            }
        }

        resultEl.innerHTML = res_;
    };

    useEffect(() => {
        const res_ult = document.getElementById("result");
        if (res_ult) {
            res_ult.innerHTML = "";
            document.querySelector("input").value = "";
        }

    }, [xyz]);

    return (
        <>
            <header className="hd">
                <h1 className={`px-5 txt-center title ${props.name === "decimal" ? "" : "hidden"}`}>How to Convert decimal or integer to Hex or Hexadecimal?</h1>
                <h1 className={`px-5 txt-center title ${props.name === "decimal" ? "hidden" : ""}`}>How to Convert Hexadecimal or Hex to decimal or integer?</h1>
            </header>
            <section id="hex-decimal">
                <div className="my-9 tl-items">
                    <p title="description">
                        {props.name === "decimal" ?
                            "You can use this tool to convert any decimal or integer number to hex or hexadecimal number. It is a great tool for the right person."
                            : "This Hex or hexadecimal convertor can help you to convert any hex number or hexadecimal number or integer or to decimal."}
                    </p>
                    <div>
                        <a href="/">Home</a>
                    </div>

                    <div>
                        <Link to="/how-to-convert-hex-to-decimal" className={`${props.name === "decimal" ? "" : "current"}`} onClick={updateState_}>Convert Hex to Decimal</Link>
                    </div>

                    <div>
                        <Link to="/how-to-convert-decimal-to-hex" className={`${props.name === "decimal" ? "current" : ""}`} onClick={updateState_}>Convert Decimal to Hex</Link>
                    </div>

                    <div>
                        <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">YouTube</a>
                    </div>

                    <div>
                        <a href="/online-text-or-code-editor-notepad.html">Online Text Editor / Notepad</a>
                    </div>
                </div>
                <div className="bold my-9">
                    {props.name === "decimal" ? "Enter Decimal Number or integer" : "Enter Hex Number"}
                </div>
                <input type="text" className="xyz-25ext-57sjdfsj hd" placeholder={props.name === "decimal" ? "Enter Decimal number" : "Enter Hex number"} onChange={(e) => { numberConvertor(e.target.value); }} />
                <button className="hd" onClick={(e) => { numberConvertor(document.querySelector('input.xyz-25ext-57sjdfsj').value); }}>Submit</button>
                <div>
                    <h3>
                        {props.name === "decimal" ? "Result in Hex or Hexadecimal" : "Result in Decimal or Integer"}
                    </h3>
                    <div id="result"></div>
                </div>
            </section>
            <Footer name="dream-board" />

        </>
    );
}

export default HexDecimal;