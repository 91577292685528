import { setPageData } from "../Libs";
import Footer from "./Footer";
import TopNav from "./TopNav";

function Support() {

    setPageData("Thanks for your support", "Support", "Support","Support")
    return (
        <section >
            <section >
                <TopNav/>
                <header className="mt-90">
                    <h1 className="py-5 fs-2 txt-c lh-40 px-10">We Appreciate Your Support</h1>
                </header>

                <section className="px-15">
                    <p>
                        We have created many useful and helpful tools, software and free courses.
                        With your support we will be able to continue with creating great tools and courses for you.
                    </p>
                    <h3>
                        You can support us in many ways:
                    </h3>
                    <ul>
                        <li>Scan the following QR-Code to donate 4.49 dollar or euro. <br />
                            <img src="/images/free-qr-code-generator-for-payment.png" className="py-5 w-256" alt="supprt page" />
                        </li>
                        <li>
                            Pay any amount to the following bank account: <br />
                            <strong>Account Number: </strong> BE29 0689 5179 6864 <br />
                            <strong>BIC Code: </strong> GKCCBEBB
                        </li>
                        <li>
                            Watch one of our videos on YouTube <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">here</a>.
                        </li>
                    </ul>

                </section>
            </section>
            <div className="mt-50"></div>
            <Footer />
        </section>
    );
}

export default Support;