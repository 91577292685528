const sideMenuContent = `
    <div class="menu-wrapper-content">
                <div class="menu-wrapper-content-hdr">
                    <div><a href="/"><img src="/images/android-chrome-512x512.png" alt="Aqyanoos company logo"></a></div>
                    <div class="side-menu-close-btn side-menu-action-btn">X</div>
                </div>
                <nav class="menu-wrapper-content-items">
                    <div class="bold">Main</div>
                    <div class="side-menu-items">
                        <a href="/" class="Home_page">Home</a><div class="line-bar"> | </div>
                        <a href="/#/services" class="side-menu-action-btn" rel="noreferrer noopener nofollow">Services</a><div class="line-bar"> | </div>
                        <a href="/#/contact" class="side-menu-action-btn" rel="noreferrer noopener nofollow">Contact</a><div class="line-bar"> | </div>
                        <a href="/jobs/full-stack-dot-net-developer-job-internship.html" class="side-menu-action-btn" >Jobs</a><div class="line-bar"> | </div>
                        <a href="/#/privacy-policy" class="side-menu-action-btn" rel="noreferrer noopener nofollow">Privacy Policy</a><div class="line-bar"> | </div>
                        <a href="/#/about" class="side-menu-action-btn" rel="noreferrer noopener nofollow">About</a>
                    </div>

                    <div class="bold">Online Courses</div>
                    <div class="side-menu-items">
                        <a href="/#/tutorials-source-code" class="side-menu-action-btn" rel="noreferrer noopener nofollow">Source Code</a><div class="line-bar"> | </div>
                        <a href="/#/tutorials" class="side-menu-action-btn" rel="noreferrer noopener nofollow">Tutorials</a><div class="line-bar"> | </div>
                        <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" class="side-menu-action-btn" target="_blank" rel="noreferrer noopener nofollow">YouTube</a>
                    </div>
                    <div class="bold">Tools</div>
                    <div><a href="/online-text-or-code-editor-notepad.html" >Online Notepad</a></div>
                    <div>
                        <a href="/best-free-android-apps.html">Free Useful Apps</a>
                    </div>
                    <div>
                        <a href="/free-online-qr-code-generator-for-payment.html" >QR-Code Generator for Payment</a>
                    </div>
                    <div>
                        <a href="/free-online-qr-code-generator.html" >QR-Code Generator - Any Text</a>
                    </div>
                    <div><a href="/#/how-to-convert-decimal-to-hex" class="side-menu-action-btn" rel="noreferrer noopener nofollow">Hex / Decimal Convertor</a></div>
                    <div class="bold">More...</div>
                    
                    <div><a href="/#/support" rel="noreferrer noopener nofollow" class="side-menu-action-btn">Support</a></div>

                </nav>
            </div>
    `;

const topMenuContent = `
    <nav class="main-nav-holder">
        <div class="menu-icon-lg">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px"
                viewBox="0 0 100 100" enableBackground="new 0 0 100 100" space="preserve">
                <g>
                    <path fill="#fff" d="M17.563,30.277h0.012c0,1.245,1.004,2.254,2.246,2.267v0.002h60.359v-0.001c1.248-0.006,2.259-1.018,2.259-2.268h0.01
                        l0-10.459h0c-0.002-1.251-1.017-2.265-2.269-2.265l0,0H19.821v0c0,0,0,0,0,0c-1.253,0-2.269,1.017-2.269,2.269
                        c0,0.039,0.01,0.076,0.012,0.115L17.563,30.277z" />
                    <path fill="#fff" d="M80.179,42.504L80.179,42.504H19.821v0c0,0,0,0,0,0c-1.253,0-2.269,1.017-2.269,2.269c0,0.039,0.01,0.076,0.012,0.115
                        l0,10.34h0.012c0,1.245,1.004,2.254,2.246,2.267v0.002h60.359v-0.001c1.248-0.006,2.259-1.018,2.259-2.268h0.01l0-10.459h0
                        C82.446,43.518,81.431,42.504,80.179,42.504z" />
                    <path fill="#fff" d="M80.179,67.454L80.179,67.454H19.821l0,0c0,0,0,0,0,0c-1.253,0-2.269,1.017-2.269,2.269c0,0.039,0.01,0.076,0.012,0.115
                        l0,10.34h0.012c0,1.245,1.004,2.254,2.246,2.267v0.002h60.359v-0.001c1.248-0.006,2.259-1.019,2.259-2.269h0.01l0-10.459h0
                        C82.446,68.468,81.431,67.454,80.179,67.454z" />
                </g>
            </svg>
        </div>
        <a href="/" class="Home_page">Home</a>
        <a class="Services_page " href="/#/services" rel="noreferrer noopener nofollow">Services</a>
        <a class="Tutorials_page " href="/#/tutorials-source-code" rel="noreferrer noopener nofollow">Source Codes</a>
        <a class="Privacy_page  hidden" href="/#/tutorials-source-code" rel="noreferrer noopener nofollow">Privacy Policy</a>
        <a class="Contact_page " href="/#/contact" rel="noreferrer noopener nofollow">Contact</a>
        <a class="About_page " href="/#/about" rel="noreferrer noopener nofollow">About</a>
    </nav>
    `;

const footerContent = `
    <section class="container-footer">
                    <section>
                        <div>MAIN</div>
                        <a href="/">Home</a>
                        <a href="/#/services" rel="noreferrer noopener nofollow">Services</a>
                        <a href="/#/tutorials-source-code" rel="noreferrer noopener nofollow">Source Code</a>
                        <a href="/#/tutorials" rel="noreferrer noopener nofollow">Tutorials</a>
                        <a href="/#/privacy-policy" rel="noreferrer noopener nofollow">Privacy Policy</a>
                        <a href="/#/about" rel="noreferrer noopener nofollow">About</a>
                    </section>
                    <section>
                        <div>CONTACTS</div>
                        <a href="/#/contact">Contact Page</a>
                        <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">YouTube</a>
                        <a href="https://www.facebook.com/CodingAqyanoos" target="_blank" rel="noreferrer noopener nofollow">Facebook</a>
                        <a href="https://twitter.com/CodingAqyanoos" target="_blank" rel="noreferrer noopener nofollow">Twitter</a>
                        <a href="https://www.linkedin.com/company/aqyanoos" target="_blank" rel="noreferrer noopener nofollow">LinkedIn</a>                        
                    </section>
                    <section>
                        <div>TOOLS</div>
                        <a href="/online-text-or-code-editor-notepad.html">Online Notepad</a>
                        <a href="/#/how-to-convert-decimal-to-hex" rel="noreferrer noopener nofollow">Hex / Decimal Convertor</a>
                        <a href="/best-free-android-apps.html">Free Useful Apps</a>
                        <a href="/free-online-qr-code-generator-for-payment.html">QR-Code Generator for Payment</a>
                        <a href="/free-online-qr-code-generator.html">QR-Code Generator - Any Text</a>
                    </section>
                    <section>
                        <div>MORE...</div>
                        
                        <a href="/#/admin" rel="noreferrer noopener nofollow">Admin</a>
                        <a href="/jobs/full-stack-dot-net-developer-job-internship.html" >Jobs</a>
                        <a href="/sitemap.html">Sitemap</a>
                        <a href="/#/support" rel="noreferrer noopener nofollow">Support</a>
                    </section>
                </section>
                <hr >
            <div class="d-flex flex-wrap f-col content-c aln-content-c fs-xs">
                <div>
                    <strong>VAT: </strong> <span>BE 1006 604 236</span>
                </div>
                <div class="d-flex flex-wrap space-between">
                    <div><strong>Opening Hours: </strong></div> <div class="ms-5"> Monday to Friday from 08:30 - 17:30</div>
                </div>               
            </div>
            <hr >
                <div class="copyright">© Aqyanoos | 2020 - ${new Date().getFullYear()}. All rights reserved.</div>
    `;

const svgLinkIcon = `
        <svg viewBox="0 0 24 24"><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>
        `;

const offlineContent = {
    sideMenuContent, topMenuContent, footerContent, svgLinkIcon
};
module.exports = offlineContent;