
import { Link } from "react-router-dom";

function Footer(props) {
    const fy = new Date().getFullYear();

    return (
        <footer className="footer" id="main-footer">
            <section className="container-footer">
                <section>
                    <div>MAIN</div>
                    <a href="/">Home</a>
                    <Link to="/services" rel="noreferrer noopener nofollow">Services</Link>
                    <Link to="/tutorials-source-code" rel="noreferrer noopener nofollow">Source Code</Link>
                    <Link to="/tutorials" rel="noreferrer noopener nofollow">Tutorials</Link>
                    <Link to="/privacy-policy" rel="noreferrer noopener nofollow">Privacy Policy</Link>
                    <Link to="/about" rel="noreferrer noopener nofollow">About</Link>
                </section>
                <section>
                    <div>
                        CONTACTS
                    </div>
                    <Link to="/contact">Contact Page</Link>
                    <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">YouTube</a>
                    <a href="https://www.facebook.com/CodingAqyanoos" target="_blank" rel="noreferrer noopener nofollow">Facebook</a>
                    <a href="https://twitter.com/CodingAqyanoos" target="_blank" rel="noreferrer noopener nofollow">Twitter</a>
                    <a href="https://www.linkedin.com/company/aqyanoos" target="_blank" rel="noreferrer noopener nofollow">LinkedIn</a>

                </section>
                <section>
                    <div>TOOLS</div>
                    <a href="/online-text-or-code-editor-notepad.html">Online Notepad</a>
                    <Link to="/how-to-convert-decimal-to-hex" rel="noreferrer noopener nofollow">Hex / Decimal Convertor</Link>
                    <a href="/best-free-android-apps.html">Free Useful Apps</a>
                    <a href="/free-online-qr-code-generator-for-payment.html">QR-Code Generator for Payment</a>
                    <a href="/free-online-qr-code-generator.html">QR-Code Generator - Any Text</a>
                </section>
                <section>
                    <div>MORE...</div>

                    <Link to="/admin" rel="noreferrer noopener nofollow">Admin</Link>
                    <a href="/jobs/full-stack-dot-net-developer-job-internship.html" >Jobs</a>
                    <a href="/sitemap.html" >Sitemap</a>
                    <Link to="/support" rel="noreferrer noopener nofollow">Support</Link>

                </section>

            </section>
            <hr />
            <div className="d-flex flex-wrap f-col content-c aln-content-c fs-xs">
                <div>
                    <strong>VAT: </strong> <span>BE 1006 604 236</span>
                </div>
                <div className="d-flex flex-wrap space-between">
                    <div><strong>Opening Hours: </strong></div> <div className="ms-5"> Monday to Friday from 08:30 - 17:30</div>
                </div>
                {
                    props.page ? props.page === "contact" ? <div>
                        <div>
                            <strong>Location: </strong> <span> Rerum-Novarumplein 181, 9000 Ghent, Belgium</span>
                        </div>
                        <div>
                            <strong>Email: </strong> <span>ssayed.kazimi@gmail.com</span>
                        </div>
                    </div> : <div>
                        <strong>Location: </strong> Ghent, Belgium
                    </div> : <div>
                        <strong>Location: </strong> Ghent, Belgium
                    </div>
                }
                {/* <div>
                    <strong>Phone: </strong> <span>+32 465 37 89 77</span>
                </div> */}
            </div>
            <hr />
            <div className="copyright">
                &copy; Aqyanoos | 2020 - <span id="current-year"> {fy}. All rights reserved.</span>
            </div>
        </footer>
    );
}

export default Footer;
