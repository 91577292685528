import TopNav from "./TopNav";
import { setPageData } from "../Libs";
import SourceCodePartial from "./SourceCodePartial";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function SourceCode(props) {

    if (props.name === '5') {
        setPageData(
            'Source Code of my YouTube Tutorials',
            "Learn to code and become the best programmer?",
            "Visit our website if you would like to become a perfect programmer and find your dream job in IT in some months",
            "html, css, javascript, js, java, python, c#, c++, learn coding, learn programming, learn javascript, learn python, learn html, learn css, learn c#, learn c++"
        );
    }

    setTimeout(() => {
        const xhx = document.querySelector(".c");
        if (xhx) {
            xhx.classList.remove("c");
            document.querySelector(".html").classList.add("c");
        }
    }, 300);

    return (
        <>
            {/* <!-- nav bar --> */}
            <TopNav cls="Tutorials_page" />

            {/* <!-- main header --> */}
            <header className="page-header">
                <h3>Source Codes of my YouTube Tutorials</h3>
            </header>
            <section id="main" className="source-codes">
                <article>
                    <nav id="tutorial-list">

                        <strong>Source Code Github Links:</strong>
                        <br /> <br />
                        <a href="https://github.com/coding-aqyanoos/react-multi-language-website" target="_blank" rel="noreferrer">React Full Course for Free - Home to Make a Multi Language Website in React.js? (Project 2024)</a> <br /> <br />
                        <a href="https://github.com/coding-aqyanoos/build-create-or-make-android-app-using-html-css-js-or-javascript" target="_blank" rel="noreferrer">Create Android App using HTML CSS and JavaScript inside Android Studio</a> <br /> <br />
                        <a href="https://github.com/coding-aqyanoos/login-registration-react-js-web-api-asp-net-core-identity-authentication" target="_blank" rel="noreferrer">Login and Registration - React.js and Web API - .NET 8 Identity Authentication</a> <br /> <br />
                        <a href="https://github.com/coding-aqyanoos/multi-language-website" target="_blank" rel="noreferrer">Build Multi-language Website in ASP .NET Core</a> <br /> <br />
                        <a href="https://github.com/Sayed94h/full-stack-ASP-NET-Core-MVC-Project-Customer-Manager" target="_blank" rel="noreferrer">Full Stack ASP .NET Core MVC Tutorial for Beginners</a> <br /> <br />
                        <a href="https://github.com/Sayed94h/school-management" target="_blank" rel="noreferrer">Manage Students (React + Web API + MongoDB Database)</a> <br /> <br />
                        <a href="https://github.com/Sayed94h/manage-appointments" target="_blank" rel="noreferrer">Manage Appointments / Dates (React + Web API + MS SQL Database)</a> <br /> <br />
                        <a href="/how-to-make-qr-code-generator-html-css-js.html">How to Make QR Code Generator Website? HTML CSS JS</a> <br /> <br />
                        <strong>Source Code Direct Content:</strong>
                        <br /> <br />
                        <Link to="/tutorials-source-code/custom-video-player-html-css-javascript" className={`tl-item cvply ${props.name === '3' ? " b-current" : ''}`}>Custom Video Player Like YouTube</Link> <br /> <br />
                        <Link to="/tutorials-source-code/data-visualization-part01" className={`tl-item dvp1 ${props.name === '7' ? " b-current" : ''}`}>Data Visualization Part 1</Link><br /> <br />
                        <Link to="/tutorials-source-code/how-to-build-online-text-editor-javascript" className={`tl-item tejs ${props.name === '6' ? " b-current" : ''}`}>Text Editor JS</Link> <br /> <br />
                        <Link to="/tutorials-source-code/convert-hex-to-decimal" className={`tl-item chtd ${props.name === '4' ? " b-current" : ''}`}>Convert Hex to decimal</Link>  <br /><br />
                    </nav>
                    {/* original */}
                    <section className={` ${props.name === '5' ? "" : " hidden"}`}>
                    <h2>Useful information</h2>
                        <p>
                            In this page you find the source codes of my YouTube Tutorials.
                            Select the article from the above list and you will see the source codes(html, css and javascript).
                            I hope you find my Tutorials helpful.
                        </p>
                        <p>
                            For each course or tutorial there is also a video course on my YouTube Channel &#40;<a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" target="_blank" rel="noreferrer noopener nofollow">Coding Aqyanoos</a>&#41;.
                        </p>
                        <p>
                            <em>Use your knowledge for good!</em>
                        </p>
                    </section>

                    {/* injected */}
                    <SourceCodePartial name={props.name} />
                </article>
            </section>
            <div className="source-space"></div>

            <Footer name="dream-board" />
        </>
    );
}

export default SourceCode;