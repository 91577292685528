import './App.css';
import "./scripts.js"
// import Footer from './components/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import HexDecimal from './components/HexDecimal';
import Privacy from './components/Privacy';
import SourceCode from './components/SourceCode';
import TextEditor from './components/TextEditor';
import Tutorials from './components/Tutorials';
import CssSelectors from './components/CSS_Selectors';
import MongoDbWebApi from './components/MongoDB_WebApi';
import PrivacyMain from './components/PrivacyMain';
import Services from './components/Services';

import SideMenu from './components/SideMenu';

import AdminPage from './components/Admin';
import NotFound from './components/404';

import {
  // createHashRouter,
  // RouterProvider,
  Routes,
  Route
} from "react-router-dom";
import Support from './components/Support.jsx';
/*
const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/questions",
    element: <Questions />
  },
  {
    path: "/youtube-tutorials-source-code",
    element: <SourceCode name='5' />
  },
  {
    path: "/youtube-tutorials-source-code/data-visualization-part01",
    element: <SourceCode name='7' />
  },
  {
    path: "/youtube-tutorials-source-code/online-text-editor-javascript",
    element: <SourceCode name='6' />
  },
  {
    path: "/youtube-tutorials-source-code/convert-hex-to-decimal",
    element: <SourceCode name='4' />
  },
  {
    path: "/youtube-tutorials-source-code/custom-video-player-html-css-javascript",
    element: <SourceCode name='3' />
  },
  {
    path: "/how-to-convert-hex-to-decimal",
    element: <HexDecimal name='hex' />
  },
  {
    path: "/how-to-convert-decimal-to-hex",
    element: <HexDecimal name='decimal' />
  },
  {
    path: "/online-text-editor-online-code-editor-online-notepad",
    element: <TextEditor />
  },
  {
    path: "/privacy/color-reference",
    element: <Privacy name="cr" />
  },
  {
    path: "/privacy/http-response-status-code",
    element: <Privacy name="hrc" />
  },
  {
    path: "/privacy/dream-board",
    element: <Privacy name="db" />
  },
  {
    path: "/privacy/text-editor",
    element: <Privacy name="te" />
  }
]);
*/
function App() {
  // console.log("path is: ", process.env.PUBLIC_URL)
  return (
    <>
      <SideMenu />

      <Routes>
        <Route path='/' element={<Home />} />

        <Route path='/about' element={<About />} />

        <Route path='/contact' element={<Contact />} />
        <Route path='/services' element={<Services />} />

        <Route path='/tutorials-source-code' element={<SourceCode name='5' />} />

        <Route path='/tutorials-source-code/data-visualization-part01' element={<SourceCode name='7' />} />

        <Route path='/tutorials-source-code/how-to-build-online-text-editor-javascript' element={<SourceCode name='6' />} />

        <Route path='/tutorials-source-code/convert-hex-to-decimal' element={<SourceCode name='4' />} />

        <Route path='/tutorials-source-code/custom-video-player-html-css-javascript' element={<SourceCode name='3' />} />

        <Route path='/how-to-convert-hex-to-decimal' element={<HexDecimal name='hex' />} />

        <Route path='/how-to-convert-decimal-to-hex' element={<HexDecimal name='decimal' />} />

        <Route path='/online-notepad' element={<TextEditor />} />

        <Route path='/privacy/color-reference' element={<Privacy name='cr' />} />

        <Route path='/privacy/http-response-status-code' element={<Privacy name='hrc' />} />

        <Route path='/privacy/dream-board' element={<Privacy name='db' />} />

        <Route path='/privacy/text-editor' element={<Privacy name='te' />} />
        <Route path='/privacy/fake-camera-detector' element={<Privacy name='fcd' />} />
        <Route path='/privacy-policy' element={<PrivacyMain name='main' />} />
        <Route path='/tutorials' element={<Tutorials name="5" />} />
        <Route path='/list-of-all-css-selectors' element={<CssSelectors />} />
        <Route path='/how-to-integrate-mongoDB-in-ASP-Net-core-web-api' element={<MongoDbWebApi />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/support' element={<Support />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      {/* <Footer /> */}
    </>

  );
}

export default App;
