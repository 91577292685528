
import { toggleMenu } from "../Libs"
function MenuIcon(props) {

    return (
        <div className={props.class} onClick={toggleMenu}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px"
                viewBox="0 0 100 100" enableBackground="new 0 0 100 100" space="preserve">
                <g>
                    <path fill="#fff" d="M17.563,30.277h0.012c0,1.245,1.004,2.254,2.246,2.267v0.002h60.359v-0.001c1.248-0.006,2.259-1.018,2.259-2.268h0.01
                        l0-10.459h0c-0.002-1.251-1.017-2.265-2.269-2.265l0,0H19.821v0c0,0,0,0,0,0c-1.253,0-2.269,1.017-2.269,2.269
                        c0,0.039,0.01,0.076,0.012,0.115L17.563,30.277z" />
                    <path fill="#fff" d="M80.179,42.504L80.179,42.504H19.821v0c0,0,0,0,0,0c-1.253,0-2.269,1.017-2.269,2.269c0,0.039,0.01,0.076,0.012,0.115
                        l0,10.34h0.012c0,1.245,1.004,2.254,2.246,2.267v0.002h60.359v-0.001c1.248-0.006,2.259-1.018,2.259-2.268h0.01l0-10.459h0
                        C82.446,43.518,81.431,42.504,80.179,42.504z" />
                    <path fill="#fff" d="M80.179,67.454L80.179,67.454H19.821l0,0c0,0,0,0,0,0c-1.253,0-2.269,1.017-2.269,2.269c0,0.039,0.01,0.076,0.012,0.115
                        l0,10.34h0.012c0,1.245,1.004,2.254,2.246,2.267v0.002h60.359v-0.001c1.248-0.006,2.259-1.019,2.259-2.269h0.01l0-10.459h0
                        C82.446,68.468,81.431,67.454,80.179,67.454z" />
                </g>
            </svg>
        </div>
    )
}

export default MenuIcon;