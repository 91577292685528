
import { toggleMenu } from "../Libs";
import { Link } from "react-router-dom";

function SideMenu(props) {

    const closeOnClickOutside = (e) => {
        const targetEl = e.target;
        console.log("Target: ", targetEl);
        if (targetEl.className.includes("menu-wrapper open")) {
            targetEl.classList.remove("open");
        }
    };

    return (
        <section className="menu-wrapper" onClick={closeOnClickOutside}>
            <div className="menu-wrapper-content">
                <div className="menu-wrapper-content-hdr">
                    <div>
                        <a href="/">
                            <img src="/images/android-chrome-512x512.png" alt="company logo" />
                        </a>
                    </div>
                    <div onClick={toggleMenu} className="side-menu-close-btn">X</div>
                </div>
                <nav className="menu-wrapper-content-items">
                    <div className="bold">Main</div>
                    <div className="side-menu-items px-5">

                        <a href="/" className='Home_page'>Home</a>
                        <div className="line-bar"> | </div>

                        <Link to="/services" onClick={toggleMenu} rel="noreferrer noopener nofollow">Services</Link>
                        <div className="line-bar"> | </div>

                        <Link to="/contact" onClick={toggleMenu} rel="noreferrer noopener nofollow">Contact</Link>

                        <div className="line-bar"> | </div>
                        <a href="/jobs/full-stack-dot-net-developer-job-internship.html" onClick={toggleMenu}>Jobs</a>
                        <div className="line-bar"> | </div>
                        <Link to="/privacy-policy" onClick={toggleMenu} rel="noreferrer noopener nofollow">Privacy Policy</Link>
                        <div className="line-bar"> | </div>

                        <Link to="/about" onClick={toggleMenu} rel="noreferrer noopener nofollow">About</Link>

                    </div>
                    {/* break */}
                    <div className="bold">Online Courses</div>
                    <div className="side-menu-items px-5">
                        <Link to="/tutorials-source-code" onClick={toggleMenu} rel="noreferrer noopener nofollow">Source Code</Link>
                        <div className="line-bar"> | </div>
                        <Link to="/tutorials" onClick={toggleMenu} rel="noreferrer noopener nofollow">Tutorials</Link>
                        <div className="line-bar"> | </div>
                        <a href="https://www.youtube.com/channel/UCf7acJJfY8vUaW_9Ef4CCkQ" onClick={toggleMenu} target="_blank" rel="noreferrer noopener nofollow">YouTube</a>
                    </div>

                    {/* break */}
                    <div className="bold">Tools</div>
                    <div>
                        <a href="/online-text-or-code-editor-notepad.html" onClick={toggleMenu}>Online Notepad</a>
                    </div>
                    <div>
                        <a href="/best-free-android-apps.html" onClick={toggleMenu}>Free Useful Apps</a>
                    </div>
                    <div>
                        <a href="/free-online-qr-code-generator-for-payment.html" onClick={toggleMenu}>QR-Code Generator for Payment</a>
                    </div>
                    <div>
                        <a href="/free-online-qr-code-generator.html" onClick={toggleMenu}>QR-Code Generator - Any Text</a>
                    </div>
                    <div>
                        <Link to="/how-to-convert-decimal-to-hex" rel="noreferrer noopener nofollow" onClick={toggleMenu}>Hex / Decimal Convertor</Link>
                    </div>
                    {/* break */}
                    <div className="bold">More...</div>
                    
                    <div>
                        <Link to="/support" rel="noreferrer noopener nofollow" onClick={toggleMenu}>Support</Link>
                    </div>
                </nav>
            </div>

        </section>
    );
}

export default SideMenu;
